$base-color: #3d3c3c;

$baseHeader-fontSize: 50px;
$baseHeader-baseline: 50px;
$baseHeader-letterSpacing: -0.08em;
$baseHeader-color: $cruiseable-blue;

$baseSubheader-fontSize: 24px;
$baseSubheader-baseline: 32px;
$baseSubheader-letterSpacing: -0.02em;
$baseSubheader-color: $base-color;

$baseH3-fontSize: 30px;
$baseH3-baseline: 36px;
$baseH3-letterSpacing: -0.04em;
$baseH3-margin: 30px 0px 12px;

$baseH4-fontSize: 20px;
$baseH4-baseline: 26px;
$baseH4-letterSpacing: -0.02em;
$baseH4-margin: 20px 0px 8px;


$baseText-fontSize: 16px;
$baseText-baseline: 24px;

$baseParagraph-margin: 0px 0px 12px;


%baseHeader{
  @extend %quicksand;
  color: $baseHeader-color;
  font-size: $baseHeader-fontSize;
  line-height: $baseHeader-baseline;
  letter-spacing: $baseHeader-letterSpacing;
}

%baseSubheader{
  @extend %openSans--light;
  font-size: $baseSubheader-fontSize;
  line-height: $baseSubheader-baseline;
  letter-spacing: $baseSubheader-letterSpacing;
  color: $baseSubheader-color;
  margin-top: 12px;
  margin-bottom: 28px;
  clear: left;
}

%baseH3{
  @extend %openSans;
  font-size: $baseH3-fontSize;
  color: $base-color;
  line-height: $baseH3-baseline;
  letter-spacing: $baseH3-letterSpacing;
  margin: $baseH3-margin;
  clear: left;
}

%baseH4{
  @extend %openSans--bold;
  font-size: $baseH4-fontSize;
  color: $base-color;
  line-height: $baseH4-baseline;
  letter-spacing: $baseH4-letterSpacing;
  margin: $baseH4-margin;
  clear: left;
}

%baseParagraph{
  margin: $baseParagraph-margin;
}

%baseText{
  font-size: 17px;
  line-height: 1.65;
  text-align: left;
  color: $base-color
}

%baseImage{
  margin-bottom: 0px; 
}

%baseAnchor:not(.btn){
  color: $cruiseable-blue;
}

%bigCap{
  float: left;
  margin:{
    right: 3px;
    top: 10px;
  }
  padding: 0px 15px;
  border-radius: 45px;
  background-color: #20b8ec;
  font:{
    size: 22px;
    weight: normal;
    family: Arial, Helvetica, sans-serif;
  }
  line-height: 15px;
  color: white;
  text-transform: uppercase;
}

@mixin bigNumberedList {
  ol {
    position: relative;
    padding-left: 0px; margin-left: 0px;
    list-style: none;
    counter-reset: ca-counter;
    > li {
      margin-bottom: 20px; padding-left: 30px;
      min-height: 45px;
      &:before {
        position: absolute; left: 14px;
        margin-top: 0px;
        text-decoration: underline;
        font-weight: 700; font-size: 15px; color: $cruiseable-blue;
        content: counter(ca-counter); counter-increment: ca-counter;
      }
    }
  }
}

@mixin image-caption{
  .image-credits > a{
    font-size: $font-size-small !important;
    text-decoration: none;
  }
}

.bigCappedLetters{
  @extend %bigCap;
  padding: 13px 14px;
}



.content{
  //line-height: 28px -> 42px -> 56px -> 70px
  $headline-fontSize: 44px;
  $headline-letterSpacing: -0.08em;

  .headline-title{
    font-size: $headline-fontSize;
    line-height: 1;
  }

  &-subtitle, h2{
    @extend %baseSubheader;
  }

  &-body{
    @extend %baseText;

    h3 {
      @extend %baseH3;
    }
    h4 {
      @extend %baseH4;
    }
    p {
      @extend %baseParagraph;
    }
    a {
      @extend %baseAnchor;
    }
    img {
      @extend %baseImage;
    }
    @include bigNumberedList;
  }

  &-share{
    margin-top: 30px;
    margin-bottom: 60px;
  }

  &--prime.content-body > p:first-of-type:first-letter,
  & .bigCapped:first-letter{
      @extend %bigCap;
       padding: 13px 14px;
       margin-top: 3px;
  }
  @include image-caption

}