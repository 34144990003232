.mediaGallery{

  @media (min-width: $screen-md-min) {
    &-nav{
      display: table;
      width: 100%;

      > ul{
        padding-left: 0;
        list-style: none;
        display: table-row;
        > li  {display: table-cell}
      }
    }
  }

  &-nav{
    > ul > li {
      border: {
        top: 1px solid $light-gray;
        left: 1px solid $light-gray;
      }
      &:last-child{
        border-right: 1px solid $light-gray;
      }
    }
  }


  &-nav-item{
    display: block;
    padding: ((50/2) - 16)*1px 0px;
    background-color: #f4f4f4;

    @extend %openSans;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #8e8e8e;



    &:hover, &.active{
      color: #ffffff;
      background-color: $cruiseable-blue;
      text-decoration: none;
    }

    &-details{
      color: #226764;
    }
  }

}