.shipSelector{
    width: 300px;
    @include transition(width 0.5s)
}

.shipSelector-list {
    list-style: none;
    padding-left: 0px;
}

.shipSelector-list--cruiselines{
    overflow-y: hidden;
    outline: 0;
    &:focus{
      box-shadow: none;
      border: none;
    }
}

.shipSelector-listContainer--ships{
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.shipSelector-dropdown{
    position: absolute; z-index: 1000;
    display: none; width: 100%;
    background-color: rgba(255,255,255,0.9);
}

.shipSelector.active{
    width: 500px;
    .shipSelector-dropdown{
        display: block
    }
}

.modal-body {
    .shipSelector {
        width: 100%;
    }

    .shipSelector-listContainer--ships{
        color: $ca-black;
    }


    .shipSelector-list li {
        cursor: pointer;
    }
}

.shipSelector-closeLink{
    position: absolute; right: 0px;
    z-index: 1001;
}