.badges{
    width: 100%;
    text-align: justify;

    ul {padding: 0; } 
    ul > li{display: inline-block; width: 8%; } 
    ul.badges-port > li{width: 9.4%; } 

    figure{text-align: center; }
    figcaption{margin-top: 5px; display: inline-block; }

    .badges-selected{opacity: .5; }
}

.disqus-attachments{
    text-align: justify;
    width: 100%;

    &-header{font-size: 20px; margin-bottom: 10px; }

    &-list{
        padding: 0;
        width: 100%;
        text-align: justify;
        list-style: none;
        @include clearfix;
    }

    &-item{display: inline-block}
    &-item img {max-height: 100%}
}

.disqus-attachments.disqus-attachments--images{
    .disqus-attachments{
        &-item{@include size(160px); overflow: hidden}
    }
}