.shipFleet-table.tableOfItems{
  border-spacing: 40px;
}

.shipFleet-cell{
  padding-bottom: 44px;
}

.shipFleet-ratingContainer{
  position: absolute;
  bottom: 19px;
  width: 100%;
}