.destinationList{

}

.destinationList-item{
  cursor: pointer;

  .destinationList--inline &{
    display: inline-block;
    margin-right: 5px;
    padding: 0px 0px 0px 10px;
  }

  .destinationList--reverse &{
    background-color: #ffffff;
    border-radius: 5px;
  }
}

.destinationList-item-action.btn.btn-link {
  padding: 5px 10px 5px 5px;
  color: $fontColor;
  .destinationList-item:hover &{ color: $ca-blue; text-decoration: none}
}

