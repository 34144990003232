.topic{
	&-head { @include cropped_thumb (100% 300px);
		> img { @include size(100% auto) }
	}

	&-subscribe { margin: 6px 0; padding: 25px 0;
				  @include c($b: #EDE9DF); @include f(1.2em); }
	&-subscribe-terms > label { margin-left: 20px; @include f(0.8em, $w: $wn); text-transform: uppercase }
	&-subscribe-terms input { @include pos($pa); left: 13px;}


	&-intro { margin: 30px 0px; @include b($r:0); @include f($a: $c); border-radius: 6px; }
	&-intro-header { margin: 0px; @include b($r:0); padding: 5px 10px;
					 @include pos($pr, -19px); display: inline-block;
	                 @include f(24px, $ws); @include sand_section; border-radius: 6px; }

	&-intro ol > li { float: $l; margin-left: 34px; padding: 0 2% 0 10px;  width: 28%;
				  	  @include f(16px, $ws, $l)	}
	&-intro-button { margin: 15px 0px; width: 260px; @include f(21px)}


	&-subtitle { @include f(2.4em, $w: $ws, $a: $l) }
	&-subtitle--secondary { margin-bottom: 22px; @include f(2.2em, $a: $l) }


	&-section-header {
		margin-top: 40px; @include f(1.2em, $w: $ws); text-transform: uppercase;
		@include cross_header($grey, #fff, 20px);
	}
	&-section { @include pos($pr); margin-bottom: 30px; }
	&-section-list--vertical {}
	&-section-linkWrapper { padding-top: 20px; @include f(1.2em, $w: $ws, $a: $c) }
	&-section-link { @include f(1.2em); @include c($ca-blue); text-transform: uppercase }
	&-section-link:hover { @include c($ca-blue-darken); text-decoration: none}

	&-section.semiopen { overflow: hidden;
		.topic-section-linkWrapper {
			@include pos($pa, $b: -20px); width: 100%;
			@include c($b: #fff)
		}
	}
	&-section.open { height: auto;
		.topic-section-linkWrapper { display: none; }}

	&-section--featured.semiopen {  height: 990px; }
	&-section--editorPicks.semiopen {  height: 500px; }
	&-section--blog_posts.semiopen {  height: 500px; }

	&-discounts { @include sand_section; @include font_section_I; }
	&-discounts-content { padding: 20px; }
	&-discounts-imgHolder { float: $r; width: 234px; }
	&-discounts-imgHolder > img { max-width: 100%; }

	&-bliss { @include b($r:6px); }
	&-bliss-header { margin: -1px -16px 32px -16px; padding: 20px 40px;
		             @include sand_section; @include f(2.2em, $ws, $a: $c);
		             border-width: 1px 1px 0 1px; border-radius: 6px 6px 0 0;
		         }

	hr { border-color: $grey}
	.ca-article-thumb { height: 18em; }
	&-section--featured {
		.ca-article-title{ overflow: hidden; height: 1.2em;
						   text-overflow: ellipsis; white-space: pre; } 
   }
}