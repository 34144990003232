.scoopit-insight{
    position: relative;
    margin-bottom: 20px; padding: 20px 20px 20px 56px;
	background-color: #F0EDED;
	&:before{ @extend %fa;
	    position: absolute; left: 16px;
		content: "\f10d";
	    font-size: 30px; color: white;
	}
}
