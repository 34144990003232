.mce-panel{
  background-color: transparent !important;
  background-image: none !important;
  border-width: 0px !important;
}

.mce-menu-item{
  background-color: #ffffff !important;
}

.mce-statusbar{
  display: none !important;
}

.mce-edit-area > iframe{
  border: 1px #d7d7d7 solid !important;
  border-radius: 6px;
}
.mce-btn{
  background-image: none !important;
  background-color: white !important;
  border: 0px !important;
  box-shadow: none !important;
}
.mce-ico{
  background-size: auto !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  height: 20px !important;
}
.mce-i-bold{
  background-image: url("/static/img/tinymce/b_icon.png") !important;
  &:before{
    content: "" !important;
  }
}
.mce-i-italic{
  background-image: url("/static/img/tinymce/i_icon.png") !important;
  &:before{
    content: "" !important;
  }
}
.mce-i-insert-link{
  background-image: url("/static/img/tinymce/link_icon.png") !important;
  width: 28px !important;
}
.mce-i-insert-media{
  background-image: url("/static/img/tinymce/small_upload_icon.png") !important;
  width: 23px !important;
}
.mce-i-bullist{
  background-image: url("/static/img/tinymce/bullet_point_icon.png") !important;
  width: 21px !important;
  &:before{
    content: "" !important;
  }
}
.mce-i-numlist{
  background-image: url("/static/img/tinymce/numbered_icon.png") !important;
  width: 21px !important;
  &:before{
    content: "" !important;
  }
}

.mce-wyswig-container{
  position: relative;
  > textarea{
    height: 400px;
    margin-top: 37px;
  }
}

.mce-switchMode{
  color: #d7d7d7;
  font-weight: bold;
  margin-left: 6px;
  &--active.mce-switchMode{
    color: #555;
    &:hover{ color: #555; }
  }
  &:hover{ color: #d7d7d7; }

  &-container{
    position: absolute;
    right: 0px;
    top: 9px;
    z-index: 10;
  }
}
