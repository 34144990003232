.sharedImages-title{
  margin-right: 1px;
  margin-bottom: 1px;
  padding: 5px 10px;
  background-color:  $ca-blue;
  color: #ffffff;
  font-size: 24px;
}

.sharedImages-col{
  width: 50%;
  float: left
}
.sharedImages-col:first-child:last-child{
  width: 100%;
}

.sharedImages-item{
  margin: 1px;
}

.sharedImages-brick{
  width: (100%/3);
  .sharedImages-col:first-child:last-child &{ width: (100%/6); }
}

.sharedImages-btnNextPage{
  margin-top: 40px;
  font-size: 1.2em;
  padding: 0.6em 1.2em;
}

.sharedImages-brick{
  display: none;

  &.loaded{
    display: block;
   }

}

.sharedImages-btnWrapper{
  width: 24%;
  margin: auto;
}
