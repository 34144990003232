.keepReading {
  margin: 30px 0px;

  &-btn { @include pos($pr); margin: auto; padding: 29px 0 10px 10px; @include size(83px 97px);
    @include f(1.2em, $wb, $a: $c, $lh: 1.2); @include c(#ffffff, $ca-blue);
    cursor: pointer; text-transform: uppercase;

    &:hover { background-color: #05CDF5 }
    &:before { @include pos($pa); left: 100%; top: 0; display: block;
      @include triangle(97px, $ca-blue, right); border-left-width: 22px;  content: ""; }
    &:hover:before{ @include triangle(97px, #05CDF5, right); border-left-width: 22px; }
  }
  &-text{
    @include pos($pa); left: 7px;
    font-size: 16px;
  }
  &-ico{
    font-size: 70px;
    margin-top: -14px;
  }
  &-content{
    @include ngSlideAnimation();
  }
}