@import "call-info";
@import "reasons-list";
@import "callback-form";

.bookNow-modalSize{
  & > .modal-dialog{
    width: 810px;
  }
}
.bookNow-modalSize--reasons{
  & > .modal-dialog{
    width: 960px;
  }
}

.bookNow{
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  padding: 0 15px;

  &-header{
    text-align: center;
    margin-bottom: 25px;
  }
  &-title{
    color: $ca-blue;
    font-family: "Quicksand", sans-serif;
    font-size: 36px;
    letter-spacing: -0.10em;
  }
  &-subtitle{
    font-weight: 500;
    font-size: 19px;
    letter-spacing: -0.02em;
    color: #5e5e5e;
    margin-top: 0px;
  }
  &-consultants{
    background-color: #f8f8f8;
  }

  &-body{
    margin-top: 25px;
  }

  &-action{
    border-right: 2px solid #d7d7d7;
    padding-right: 50px;
  }
  &-reasons{
//    border-left: 2px solid #d7d7d7;
//    margin-left: -2px;
//    padding-left: 50px;
  }
  &-callInfo{

  }

  &--asPage{
    border: 0;
  }
}

.bookNow-consultant{
  padding: 15px 0px;

  @include make-sm-column(12 / 6);
  @include make-xs-column(12 / 6);

  text-align: center;

  &-image{
    > img{
      border-radius: 3px; display: inline-block;
      border: 1px solid #B3AB9D;
    }
  }
  &-caption{
    margin-top: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: $ca-blue;
  }
}

.bookNow-callback{
  margin-top: 10px;
   @include clearfix;

  &-title{
    @extend .callInfo-title;
    margin-top: 34px;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
  }

  &-success{
    text-align: center;
    font-size: 24px;
    color: $ca-blue;
  }

  &-form{}
}

.bookNow-offer{
  margin-top: 10px;
  padding: 5px;
  background-color: #f8f8f8;
  text-align: center;

  &-head{
    margin-top: 10px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }

  &-text{
    font-size: 16px;
    line-height: 24px;
  }

  &-code{
    font-size: 40px;
    color: $ca-blue;
    margin-bottom: 0px;
  }

  &-expert{
    font-size: 13px;
    line-height: 20px;
  }
}

.bookNow-discounts{
  margin-left: 15px;
  font-size: 15px;
  line-height: 22px;

  &-head{
    font-size: 16px;
    font-weight: 600;
  }

  &-list{
    list-style-position: inside;
    padding: 0px;
  }

  &-sublist{
    padding-left: 20px;
    list-style-type: disc;
  }

}

.bookNow-reasons{
  &-header{
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
  &-list{
    margin-top: 26px;
    padding-left: 55px;
  }
  &-cliaLogo{
    height: 86px;
    margin-right: 10px;
  }
  &-ready{
    margin: 15px 0px;
  }
  &-link{
    font-size: 18px;
    font-weight: 600;
  }
}

.bookNow-footer{
  text-align: center;

  &-body{
    font-size: 18px;
    font-weight: 500;
    color: #5e5e5e;
  }
  &-info{
    display: inline-block;
    font-size: 13px;
    color: #9c9c9c;
    font-weight: 500;
  }
  &-info:first-child{
    padding-right: 20px;
  }
}

.bookNow-formModal{
  .modal-content{
    padding: 0px 20px 20px;
  }
  .modal-dialog{
      width: 800px;
  }
}