.authorMeta{
  &-name{
    font-size: 20px;
    font-weight: 600;
    color: #404040
  }
  &-description{
    font-size: 17px;
    color: #6d6d6d;
  }
  &-thumbnail{
    @include size(80px 80px);
    border-radius: 50%;
    float: left;
    margin-right: 15px;
  }

  &-thumbnail--small{
    $photoSize: 40px;
    @include size($photoSize $photoSize);
    border-radius: 50%;
  }

  > .cssTable-cell{
    padding: 4px 12px;
    text-align: center;
  }

}

.authorMeta--withThumbnail{
  @include clearfix;

  margin-top: 50px;
  border-top: 1px solid $cruiseable-blue;
  padding-top: 20px;

  .authorMeta-name{
    font-size: 20px;
    font-weight: 600;
    color: $cruiseable-blue
  }
}

.authorMeta--inListing{
  .authorMeta-name{
    font-size: 18px;
    color: $cruiseable-blue
  }

  > .cssTable-cell{
    padding: 8px 4px;
    text-align: center;
  }

}