@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);

@import  'variables';
@import '../cruise/app/bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_variables.scss';
@import '../cruise/app/bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_mixins.scss';
@import '../cruise/app/bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_scaffolding.scss';
@import '../cruise/app/bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/type';
@import '../cruise/app/bower_components/bourbon/dist/_bourbon.scss';
@import '../cruise/app/bower_components/angular-ui-select/dist/select.css';

$input-border-focus: $input-border !default;

%quicksand{
  font-family: 'Quicksand', sans-serif;
}

%openSans{
  font-family: 'Open Sans', sans-serif;
}

%openSans--bold{
  @extend %openSans;
  font-weight: 700;
}
%openSans--semiBold{
  @extend %openSans;
  font-weight: 600;
}

%openSans--light{
  @extend %openSans;
  font-weight: 300;
}

%btn{
  border-style: solid;
  text-transform: uppercase;
  vertical-align: baseline;
}

%centerImage{
  width: 100%;
  height: 100%;
  max-width: 1600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

%fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@import "utilities";

@import "animation";
@import "mixins";
@import "ca";

@import "content";

@import "buttons";
@import "tinymce";
@import "content-listing";
@import "highlight-bg";
@import "media-gallery";
@import "slider";
@import "balloon";
@import "text-list";
@import "user-avatar";
@import "subscribe-form";
@import "review";
@import "rating-block";
@import "featured-images";
@import "author-meta";
@import "icons";
@import "fluid-bar";
@import "modal";
@import "main-image";
@import "oobigo";
@import "rewards";
@import "components/app-promo";
@import "components/module";
@import "components/img-holder";
@import "components/list-of-features";
@import "components/obj-holder";
@import "components/price";
@import "components/route-table";
@import "components/route-map";
@import "components/tag-search";
@import "components/hearting";
@import "components/stashing";
@import "components/share-image";
@import "components/select-image";
@import "components/spinning-loader";
@import "components/range-slider";
@import "components/slider";
@import "components/destination-list";
@import "components/list-of-links";
@import "components/image-container";
@import "components/masonry";
@import "components/more-loader";
@import "components/ui-increment";
@import "components/image-edit";
@import "components/ship-fleet";
@import "components/disqus";
@import "components/blog";
@import "components/badges";
@import "components/topic";
@import "components/weather-widget";
@import "components/tagging";
@import "components/shared-images";
@import "components/cruise-search/cruise-search";
@import "components/cruise-search/cruise-search-filters-destination";
@import "components/cruise-search/cruise-search-filters-price";
@import "components/cruise-search/cruise-search-filters-date";
@import "components/cruise-search/cruise-search-filters-size";
@import "components/cruise-search/cruise-search-filters-passengers";
@import "components/cruise-search/cruise-search-bar";
@import "components/cruise-search/cruise-search-results";
@import "components/vibes-filters";
@import "components/qa";
@import "components/lib-uploader";
@import "components/stashboard";
@import "components/compare-cruises";
@import "components/image-popup";

@import "components/beta-invitation";
@import "components/book-now/book-now";
@import "components/keep-reading";
@import "components/user-profile" ;
@import "components/userListing" ;
@import "components/followers" ;
@import "components/ship-selector" ;
@import "components/scoopit";

@import "components/top-bar";
@import "components/footer";

@import "page/home";
@import "page/home-new";
@import "page/login";
@import "page/search";
@import "page/profile";
@import "page/cruise";
@import "page/ship";
@import "page/galleria";
@import "page/visual-list";
@import "page/post";
@import "page/error";
@import "page/image-page";
@import "page/community";
@import "page/glossary";
@import "page/plan-cruise";
@import "page/group-cruises";
@import "page/ship-discovery";

@import "admin";


.col-lg-2-4 { @include make-lg-column(2.4) }
.col-md-2-4 { @include make-md-column(2.4) }
.col-sm-2-4 { @include make-sm-column(2.4) }

body { @extend %openSans; color: $fontColor; }

a { color: $cruiseable-blue; }
a:hover { @include transition(all 0.2s $ease-out-cubic); color: $ca-blue; }
a:focus { outline: 0px; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak { display: none !important; }

.text-pre{ white-space: pre; }

.postForm{
  &-textForm textarea { min-height: 400px; }
  &-editorNotes { min-height: 600px; }
  &-formWrapper { margin-bottom: 20px; }
  &-thumbnail { display: table-cell; vertical-align: middle; @include size(229px 229px); }
}

#lightbox{ line-height: 0px; }

.sorter {
  &--negateMargin { margin-top: -20px; }
  &-title { text-transform: uppercase; font-weight: bold; font-size: 18px; }
  &-list { font-size: 18px; display: inline-block; vertical-align: baseline; }
  &-list > li { padding: 0px; cursor: pointer; }
  &-list > li:before { content: " |"; color: $fontColor; padding: 0px 5px; }
  &-list > li:first-child { padding-left: 5px; }
  &-list > li:first-child:before { content: ""; }
  &-list > li.active { color: $cruiseable-blue; }
}

.cssTable { display: table;
  &-row{ display: table-row }
  &-caption{ display: table-caption; caption-side: bottom; }
  &-row--col-2 .cssTable-cell { width: 50%; }
  &-row--col-3 .cssTable-cell { width: 33%; }
  &-row--col-4 .cssTable-cell { width: 25%; }
  &-cell { display: table-cell }
  &-fullWidth{ width: 100%; }
}

.ribbon{
  font: bold 15px Sans-Serif;
  color: #333;
  text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
  text-align: center;
  @include transform(rotate(45deg));
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);

  &-wrapper{
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
  }

  &--blue{
    background-color: #B3E3E6;
    @include background-image(linear-gradient(top, #b3e3e6, #66c7cd));
     color: #555;
  }

  &--green{
    background-color: #BFDC7A;
    @include background-image(linear-gradient(top, #bfdc7a, #8ebf45));
    color: #6a6340;
  }

}



.tour{
  $paddingInBanner: 10px;

  &-body{
    padding-bottom: 50px;
  }

  &-duration{
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: $paddingInBanner;
  }

  &-price{
    float: right;
    padding: $paddingInBanner;
  }

  &-btn{
    position: absolute;
    bottom: 0px;
    @include border-top-radius(0px);
  }
}

.promotionSpace{
  margin-right: 5px;

  &-cross{
    position: relative;
    display: inline-block;
    &:before {
      border-bottom: 2px solid #ff2400;
      @include transform(skewY(-10deg));
      content: '';
      width: 100%;
      position: absolute;
      right: 0;
      top: 50%;

    }
  }
}

.centeredBlock{
  position: absolute;
  width: 100%;
  height: 60%;
  top: 40%;
}

.tableOfItems{
  border-spacing: 20px;
  border-collapse: separate;
  margin-left: -20px;
  width: 100%;
//  max-width: none;
  margin-right: -20px;
}

.googleMap{
  width: 100%;
  height: 400px;

  &--inModal{
    height: 100%;
  }
}

.twoDirectionNav{
  margin-top: 30px;
  margin-bottom: 60px;
}

.highLightText{
  text-transform: uppercase;
  font-weight: bold;
}

.muted{
  color: #999;
}

a.noStyle{
  color: inherit;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
 }

.placeholder{
  &.ui-edit-inLine{
    font-style: italic;
  }
}

.ui-edit-inLine{
  position: relative;
  display: inline-block;

  &:focus{
    outline: solid $cruiseable-blue 2px;
  }

  &::after{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    min-width: 80px;
    background-color: rgba(255,255,255, 0.9);
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    content: "Click to edit";
    color: $cruiseable-blue;
    padding-top: 2%;
    cursor: text;
  }

  &:hover{
    &::after{
      display: block;
    }
  }


  &:focus::after{
    display: none;
  }
}

[image-gallery]{
  height: 820px;
}

[image-gallery].image-gallery--list{
  height: auto;
}

@import "styles_override";



.moderator{}


.btn.moderator-btn{
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.ca-image-border{
  border: 1px solid #B3AB9D;
}

.croppedImage{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.relatedArticle-carousel{
  height: 400px;
  padding-top: 6px;
}

.on-hvr-target{@include display_on_hover('.on-hvr-trigger')}


.formPost{
  &-btn.btn{
    padding: 9px 27px;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
    text-transform: none;
  }
}

.label{
  &.label--primary{
    background-color: $cruiseable-blue;
  }
}

.sendMail{
  .control-label{
    @extend .sr-only;
    @extend .sr-only-focusable;
  }
  &-message textarea{
    min-height: 150px;
  }
}


.featuredList{
  @extend .list-unstyled;

  > li {
    margin-top: 30px;
    color: #404040;

    &:before{
      font-size: 20px;
      vertical-align: middle;
      content: "\2022  ";
      color: #ff5748; /* or whatever color you prefer */
    }
  }
}

.headline{
  margin-top: 20px;
  margin-bottom: 60px;

  &-title{
    @extend %baseHeader;
  }
}

.pageHeader{
  $font-height: 40px;
  $block-height: 88px;

  background-color: white;
  font-size: 12px;
  padding: ($block-height - $font-height)/2 0;

//  &--topBorder{
//    border-top: solid 1px $light-gray;
//  }

  &-header{
    @extend %quicksand;
    font-size: $font-height;
    color: #4B4B4B;
    text-align: center;
  }
}

.importantData{
  text-align: center;
  margin-bottom: 40px;

  &-title{
    text-align: left;
    margin-bottom: 0px;
    @extend .review-subtitle
  }

  &-value{
    margin-top: 40px;
    @extend %quicksand;
    display: block;
    font-size: 60px;
    line-height: 60px;
    color: $cruiseable-blue;
  }

  &-label{
    text-transform: uppercase;
    font-size:15px;
    color: #8e8e8e;

    &-more{
      color: $cruiseable-blue
    }
  }
}

.importantData-default{
  .tooltip-inner{
    margin-left: -200px;
  }
}

.numberedList{
  list-style-type: decimal;
  margin-top: 24px;

  &-item{
    color: #ff5748;
    font-size: 17px;
    font-weight: bold;
  }
  &-text{
    color: #575757;
    font-weight: normal;
  }
}

body.pageState-modal{
  overflow: hidden;
}

.quotations{
  text-align: center;

  &-title{
    margin-top: 36px;
    margin-bottom: 36px;
    text-align: left;
    @extend .review-subtitle
  }

  p {
    @extend %quicksand;
    font-size: 30px;
    line-height: 1.5;
    letter-spacing: -0.06em;
    color:#6d6d6d;
  }

  a{
    margin-top: 2px;
    margin-bottom: 30px;
    display: inline-block;
    font-size: 24px;
    color: $cruiseable-blue;

  }
}


.search{
  margin: 20px 0px 60px;

  @include ca-button
}

body {
    width:100%;
    height:100%;
}

.animated-placeholder{
  @include placeholder {
    @include transition-property(opacity);
    @include transition-duration(0.5s);
  }
}
.animated-placeholder.change{
  @include placeholder{
    opacity: 0;
  }
}

.searchWebsite{

  &-input{
    border-radius: 16px;

    @include placeholder {
      &::before{
        font-family: FontAwesome;
        content: "\f002";
        padding-right: 5px;
      }
    }
  }

}


.socialActions{
  @extend .list-inline;
  height: 20px;
  margin-bottom: 40px;
  font-size: 17px;

  &--circleIcons{
    height: 40px;
  }

  > li, > div{
    display: inline-block;
    padding: 0px 7px;
//    vertical-align: middle;
  }

  .fa {
    color: $cruiseable-blue;
  }
}

.socialActions-iconCircle{
  display: block;
  @include size(38px 38px);
  background-repeat: no-repeat;

  @each $icon in twitter, comment, email, facebook, google_plus, like, pinterest, tumblr, save {
    &-#{$icon} {
      background-image: url('../img/common/social/#{$icon}_icon.png');
    }
  }
}


.text-success--ca{
  color: $ca-blue;
}

.phoneNumber{
  &:hover, &:focus{
    text-decoration: none;
    color: $ca-blue;
  }
}


.dl--primary{
  text-align: center;
  > dt{
    text-transform: uppercase;
    color: $ca-blue;
  }
  dd{

  }
}

ul.list--coral, ul.list-coral{
  list-style: none;
  padding-left: 0px;

  > li:before{
    margin-right: 20px;
    font-size: 150%;
    content: "• ";
    color: $ca-red;
    line-height: 100%;
    vertical-align: sub;
  }
}

.sticky { @include pos($pf, $i: 1031); }
.sticky.sticky--fullWidth { width: 100%; }
[load-more-html] { overflow: hidden; @include pos($pr);
  .loadMore-link { @include pos($pa, $b: 0, $l: 0); width: 100%;
                   @include f($a: $c); @include c($b: #fff) }
  .loadMore-link button { @include f(1.4em, $c: $ca-blue) }
  .loadMore-link button:hover { @include c($ca-blue-darken); text-decoration: none }
  .highlightBG & .loadMore-link  { @include c($b: $ca-bg-grey) }
}

.magazine-content { margin-top: 20px; }
.magazine-content .col-md-4 { padding-right: 5px; padding-left: 5px; }

.magazine-content .ca-topic { overflow: hidden }
.magazine-content .ca-topic-title { z-index: 101 }
.magazine-content .ca-topic img { @include transform(scale(1.2)); @include transition(all 0.3s ease-in-out); }
.magazine-content .ca-topic:hover img { @include transform(scale(1)); @include transition(all 1s ease-in-out); }

a[name] {position:absolute;margin-top:-60px}
