.profile{

  &-imageBg{
    width: 100%;
    height: 275px;
    background-size: cover;
    background-position: 50% 50%;
  }

  &-imageBg-btn{
    position: absolute;
    top: 20px;
    left: 55px;
  }

  &-avatar{
  }
  &-avatar-img{
    position: absolute;
    top: -36px;
    left: 50px;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    border: 1px  solid;
    padding: 2px;
    background-color: white;

    .ui-edit.ui-edit-image .edit-menu{
      width: 120px;
      background-color: rgba(255, 255, 255, 0.8);
      height: 120px;
      border-radius: 50%;
      min-width: initial;
      margin-left: -3px;
      padding: 27px 14px;
    }

  }

  &-avatar-prompt{
    text-align: center;
    font-size: 20px;
    margin-top: 14px;
  }

  &-names{
    margin-left: 170px;
  }

  &-userName{
    font-size: 21px;
    @extend %openSans--semiBold;
    color: #3d3c3c;
    margin-bottom: 0px;
  }
  &-nickname{
    font-size: 14px;
    color: #5e5e5e;
    margin-top: 4px;
  }

  &-table{
    text-align: center;
    margin: 10px 0px;
    padding: 0px 40px;
    border-left: 2px solid #eeeeee;
    border-right: 2px solid #eeeeee;

    thead > tr > th{
      border-bottom: 0;
      padding: 0 8px;
      font-size: 14px;
      text-align: center;
      letter-spacing: -0.02em;
      font-weight: normal;
      color: #828282;
    }

    tbody > tr > td{
      border-top: 0;
      padding: 0 8px;
      font-size: 24px;
      letter-spacing: -0.04em;
      color: $cruiseable-blue;
    }

    .modal--open {
      cursor: pointer;
    }

    td.modal--open {
      text-decoration: underline;
    }
  }

  &-followersTrigger{

  }

  &-location{
    text-align: center;
    margin-top: 10px;
  }
  &-location-marker{
    color: $cruiseable-blue;
    font-size: 37px;
    vertical-align: sub;
    margin-right: 5px;
  }
  &-location-city{
    font-size: 21px;
    color: #5e5e5e;
    letter-spacing: -0.02em;
  }

  &-column-left {
    padding-top: 43px;
  }

  &-column-right {
    padding-top: 20px;
    background-color: #f8f8f8;
    border-right: 20px solid white;
  }

  &-separator{
    margin: 0 20px;
  }

  &-btn-holder{
    @include clearfix;
  }

  &-rowBtn{
    @include make-row(10px)
  }

  &-colBtn{
    @include make-md-column(6, 10px)
  }

  &-invitationBtn.btn{
    font-size: 16px;
  }

  &-changeUrlBtn.btn{
    margin-top: 10px; 
    font-size: 16px;
  }

  &-btn.btn{
    text-transform: none;
    padding: 7px 14px;
    font-size: 18px;
  }
}

.aboutUser{

  hr{
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &-score{
    text-align: center;
    color: $cruiseable-blue;
    font-size: 54px;
    letter-spacing: -0.06em;
    &-star{ font-size: 26px; vertical-align: top; }
  }

  &-header{
    color: #3d3c3c;
    line-height: 1.1;
    letter-spacing: -0.04em;
    font-weight: bold;
    font-size: 14px;
  }
  &-subheader{
    color: #3d3c3c;
    line-height: 1.1;
    letter-spacing: -0.04em;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 0px;
  }
  &-text{
    color: #5e5e5e;
    font-size: 12px;
    line-height: 1.1;
    letter-spacing: -0.04em;
  }

  &-links{
    margin-top: 13px;
    font-size: 12px;
    line-height: 1.1;
    letter-spacing: -0.04em;

    li {
      margin-top: 5px;
    }

  }

  &-myWork{
    ul{
      padding-left: 14px;
      list-style: none;
    }
    li{
      position: relative;
      &:before{
        content: "\2022";
        color: #d95972;
        position: absolute;
        left: -14px;
        top: -7px;
        font-size: 21px;
      }
    }
    p {
      color: #5e5e5e;
      font-size: 12px;
      line-height: 1.1;
      letter-spacing: -0.04em;
    }
  }
  &-myBrand{
    width: 100%;
  }
}

.userContent{
  & { margin-bottom: 20px; border: 1px solid #eeeeee; padding: 20px; background-color: #ffffff; }
  &-header { margin-top: 1px; margin-bottom: 18px; border-bottom: 1px solid #eeeeee; padding-bottom: 3px;
             font-size: 27px; color: #474747; letter-spacing: -0.04em; }
  &-header  span {font-size: 18px; }
  &-item { position: relative; }
  &-info { position: absolute; top: 3px; z-index: 100; left: 20px; }
  &-create { float: right; }
  .col-md-6:nth-child(2n+1),
  .col-md-4:nth-child(3n+1) { clear: $l }
}


.select-bgImage{
  border-radius: $border-radius-base;
  border: #9c9c9c solid 1px;
  padding: 30px;

  &-btn{ font-size: 21px; text-transform: none; @extend %openSans--semiBold}
  &-or{ font-size: 21px }
  &-container:nth-child(odd){
    clear: left;
  }
}
a.thumbnail.select-bgImage-thumbnail{
  border-radius: 0px;
  margin: 15px 0px;
  &.active{
    border-color: #d95972
  }
}

.aboutUser-stats {
  margin: 15px 0;

  td {
    padding: 0 10px;
    font-size: 24px;
    text-align: right;
    color: $cruiseable-blue;
  }

  th {
    font-weight: normal;
    font-size: 16px;
    color: #828282;
  }

  input[type=number] { width: 65px }
}

.aboutUser-share {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;

  .socialActions {
    margin: 15px 0;
  }

  .socialActions > li {
    padding: 0 3px;
  }
}