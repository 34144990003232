.tagSearch{
  position: relative;

  &-input{ border-radius: 16px; }
  &-loader{
    position: absolute;
    right: 11px;
    top: 9px;
    font-size: 17px;
    color: $ca-blue
  }
}
