.userProfileUrl{

  &-title{
    margin-top: 0px;
    font-size: 24px;
  }

  &-nameForm{
    margin-bottom: 10px;
  }

  &-checkBtn.btn{
    padding: 1px 5px;
    font-size: 16px;
    line-height: 21px;
    text-transform: none;
  }

  &-stateBox--newNameEntered {
    margin: 15px 0px;
  }

  &-previewLegend {
    font-size: 20px;
    margin-bottom: 0px;
  }

  &-urlInputLegend{
    color: $ca-blue;
  }

  &-urlPreview{
    color: $ca-blue;
    margin-right: 10px;
  }



  &-availability--available{
    color: #61c227;
  }

  &-availability--unavailable{
    color: $ca-red;
  }

  &-changeBtn.btn {
    font-size: 18px;
  }

  &-stateBox--nameChanged {
  text-align: center;
  font-size: 20px;
}

}






