.highlightBG  .homePage-section-header > span { background-color: $ca-bg-grey !important }

.homeNew {
  .homePage{
    &-section-header { margin: $header-lh 0; @include cross_header($grey, #fff, 20px);
                       @include f(2.4em, $ws);}
    &-section-subheader { margin: 1.4em 0 $header-lh; @include f(1.6em, $wn, $a: $c, $c: $ca-blue); }
    &-section-header + &-section-subheader { margin-top: -1.3em}
    .highlightBG &-section-header { @include cross_header($grey, $ca-bg-grey, 20px); }
    &-section-link { display: block; @include f(1.4em, $a: $c); text-transform: uppercase;  }
    &-section-link:hover { @include c($ca-blue-darken); text-decoration: none }

    &-search { padding: 10px 0; @include c($b: $ca-sand); @include f() }
    &-search-form { display: inline-block; @include f(1.2em); }
    &-search-form .dropdown-menu { min-width: 100% }
    &-search-form .search-loader { right: 65px; top: 8px; font-size: 20px; }
    &-search-label { @include f(1.4em, $ws, $a: $r, $lh: 1.7em); text-transform: uppercase }
    &-search cruise-search-bar { display: inline-block; vertical-align: text-bottom; }
    &-search hr { margin: 12px 10%; border-color: $ca-black }

    &-searchResults { margin-top: 20px; }

    &-deal { margin-bottom: 30px; @include c($b: #fff); }
    &-deal-thumb { @include cropped_thumb(100% 250px); }
    &-deal-caption { @include pos($pa, $b: 0px); box-shadow: inset 0px -22px 38px -16px rgba(0,0,0,0.79);
                     margin-bottom: 0px; padding: 10px; width: 100%; @include f($c: #fff) }
    &-deal-content { padding: 10px; }
    &-deal-header { margin-bottom: $header-lh/4; @include f(1.5em, $wn, $c: $ca-blue) }
    &-deal-desc { margin-bottom: 0; @include f($c: $fontColor); 
                  overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
    &-deal-promo { border-left: $default-border; height: 67px; }
    &-deal-promoTxt { @include vertical_align; @include f(1.8em, $a: $c, $lh: 1, $c: $ca-red); }
    &-deal-promoTxt > span { display: block }
    &-deal-promoTxt > .small { margin-bottom: 4px; @include f(0.5em); }

  &-article-btn { width: 80%; margin: 0px auto 10px;}

    &-partners { padding-top: 1px; text-align: center; }
    &-partners-title { @include f(18px, $c: $ca-black); }
    &-partners-list > li { margin: 10px 30px; }
  }

  $sliderSize: 400px;
  .homePage-slide{
    &-container{ @include size(100% $sliderSize) }
    &-image{ @include pos($pr); height: $sliderSize; background-size: cover; background-position: 50% 50%; }
    &-category { @include pos($pa, $t: 30px, $l: 30px); padding: 5px 10px; @include c(#fff, rgba(0,0,0,0.3));
                 @include f(1.4em); text-transform: uppercase}
    &-caption { @include pos($pa, $b: 70px); margin: 0 80px; padding: 20px;
                @include c(#fff, rgba(0,0,0,0.3)); @extend %openSans--light; }
    &-caption-title { margin-top: 0px; @include f(3.2em, $wn, $c: #fff);}
    &-caption-subtitle { margin-top: -5px; @include f(1.8em, $wl); }
    &-caption--onRight{ right: 0px; }
    &-btn { padding: 8px 50px; }
  }

  .homePage{
    .oobigo-topic { padding-bottom: 20px; }
    .oobigo-topic .btn { margin-top: 20px; }
  }
}