.cruiseFilter-passengers{
  margin-top: 15px;
}

.cruiseFilter-passengers-input{
  width: 50%;
  float: left;
}
.cruiseFilter-passengers-kids{

  .ui-increment-count {
    background-color: #baddf5;
  }
}
