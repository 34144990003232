.cruiseSearch-nav{
  margin-bottom: 0px;
  padding-left: 0px;

  &-element{
    display: inline-block;
    padding-right: 6px;
    margin-right: 9px;
    height: 25px;
  }

  &-link{
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #000000;
    line-height: 25px;
  }
  &-link:hover{ text-decoration: none; color: $cruiseable-blue; }
  &-link:focus{ text-decoration: none; color: $cruiseable-blue }
  &-link >.caret{ color: $cruiseable-blue; }
  &-link >.cruiseIco{
    margin-right: 5px;
    background-position: 100% 0px;
  }
}

.cruiseSearch-filters{
  position: relative;
  text-align: left;
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.cruiseSearch-filter{
//  position: absolute;
//  width: 940px;
//  right: 50px;
//  top: 12px;

  z-index: 1050;
}

