.animate-fade.ng-enter, .animate-fade.ng-leave {
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-fade.ng-enter,
.animate-fade.ng-leave.ng-leave-active {
  opacity:0;
}

.animate-fade.ng-leave,
.animate-fade.ng-enter.ng-enter-active {
  opacity:1;
}



.animate-drop.ng-enter, .animate-drop.ng-leave {
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;

}


.animate-drop.ng-enter,
.animate-drop.ng-leave.ng-leave-active {
  max-height: 0px;
  overflow: hidden;
}

.animate-drop.ng-leave,
.animate-drop.ng-enter.ng-enter-active {
  max-height: 700px;
}

@mixin ngFadeInAnimation {
  &.ng-enter{
    -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  }

  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity:0;
  }

  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity:1;
  }
}

@mixin ngSlideAnimation {
  &.ng-hide-add.ng-hide-add-active,
  &.ng-hide-remove.ng-hide-remove-active {
    -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  }

  &.ng-hide,
  &.ng-hide-remove.ng-hide-remove-active{
    height: 0px;
    overflow: hidden;
  }

  &.ng-hide-remove.ng-hide-remove-active {
    height: auto;
  }
}