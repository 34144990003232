.imgHolder{

  &-wrapper{}
  &-img{
    width: 100%;
  }
  &-img--rounded{
    @include border-top-radius(6px)
  }
  &-caption{
    padding: 10px;
  }
}