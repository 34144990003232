.followersModal{
  padding-top: 10px;
}

.followersModal-switch{
  color: #616161;
  &.active{ color: $ca-red; }
  &:hover, &:focus{
    text-decoration: none;
  }
}
.followersModal-countLabel{

}
.followersModal-count{
  font-size: 42px;
  line-height: 1em;
  font-weight: 700;
}
.followersModal-container{
    padding: 25px 0;
}
.followersModal-followers{}


.followersUsers{
  .moreLoader-btn{
    font-size: 14px;
    padding: 3px;
  }
}