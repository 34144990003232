.slider{
  position: relative;

  &--inLightbox{
    display: table;
    max-width: 95%;

    .slider-content-item{
      height: 639px;
      display: table-cell;
      vertical-align: middle;
    }

    .slider-images > .slider-caption{
      position: absolute;
    }

  }

  &-content{
    @extend .list-unstyled;


    img{
      width: 100%;
    }
  }

  &-content-item{
    @include center-block();
    @extend %centerImage;
    text-align: center;
    max-width: 100%;
//    background-size: cover;


    &--single{
      height: 800px;
    }

    > img {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }

  }

  &-info {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    text-align: center;

    > p {
      display: inline-block;
      padding: 5px 20px;
      font-weight: bold;
      background: $grey;
      color: $ca-grey3;
      opacity: .8;
    }
  }

  &-images{
    @extend .list-unstyled;
    margin-bottom: 0px;
    position: relative;
  }

  &-image{


    >img{
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 440px;
      @media screen and (min-height: $screen-height-sm) and (max-height: $screen-height-md) {
        max-height: 600px;
      }
      @media screen and (min-height: $screen-height-md) and (max-height: $screen-height-lg) {
        max-height: 866px;
      }
      @media screen and (min-height: $screen-height-lg) {
        max-height: 984px;
      }
    }
  }

  &-nav{
    $arrow-size: 40px;
    $nav-size: 200px;
    $arrow-gutter: 20px;

    position: absolute;
    top: 0;
    height: 100%;
    width: $nav-size;

    &--left{
      left: 0;
      background: url("../img/review__ship/arrow_left.png") no-repeat $arrow-gutter center;

    }
    &--right{
      right: 0;
      background: url("../img/review__ship/arrow_right.png") no-repeat (($nav-size - $arrow-size) - $arrow-gutter)  center;
    }
  }

  &-order{
    $size: 50px;
    margin-right: 14px;
    font-size: $size * 0.8;
    background-color: white;
    color: black;
    @include size($size $size);
    line-height: $size;
    border-radius: 50%;
    text-align: center;
    float: left;
  }

  &-caption{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0 10px;
    background-color: rgba(0,0,0,0.5);
    font-size: 13px;
    color: #ffffff;

    &--black{
      background-color: #000000;
    }

    &-author{
      text-align: right;
    }
  }

  &-btn{
    @extend %openSans;
    position: relative;
    z-index: 1000;
    font-size: 13px;
    color: #ffffff;
    margin-left: 7px;
    margin-bottom: 7px;

    &:hover{
      color: #ffffff;
    }

    &-fullscreen{
      display: block;
      padding-right: 25px;
      background: url("../img/review__ship/fullscreen-6-icon.png") no-repeat center right;
    }

//    &-heart,
    &-more{
      display: inline-block;
      float: right;
      @include text-hide();
      @include size(35px 28px);
    }

//    &-heart{
//      background: url("../img/review__ship/favorite-icon-path 2.png") no-repeat center center;
//    }

    &-more{
      background: url("../img/review__ship/more_btn.png") no-repeat center center;
    }
  }

}

.slider-actions{
  padding: 10px 20px;
  position: absolute;
  right: 0px;
  top: 0px;

  &--inLightbox{
    z-index: 1100;
  }

  &-btn{
    position: relative;
    z-index: 1000;
  }

  &-bar{
    li{
      vertical-align: top;
    }
  }
  .stashing-message { top: -8px; }
}

.slider--responsiveNav{
  position: relative;
  .slider{
    position: static;
  }
  .slider-caption{
    position: relative;
    @include clearfix();
  }
}

.rn-carousel-control{
  @extend .slider-nav;
  // height: 710px;
}

.rn-carousel-control.rn-carousel-control-prev{
  @extend .slider-nav--left;
  &:before{ content: ""; }
}

.rn-carousel-control.rn-carousel-control-next{
  @extend .slider-nav--right;
  &:before{ content: ""; }
}