.style {
	color: #575757;
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	line-height: 19px;
}
.review, .content-body{ 
	a:not(.btn) {
		text-decoration: underline;
		&:link{ color: #20b8ec; }
		&:visited{ color: #6CCEF0; text-decoration: none !important; }
		&:hover{ color: #00D8F5; }
		&:active{ color: #00D8F5; }
		&:focus{ color: #00D8F5; }
	}
}

.review a.icon, .content-body a.icon {
	border-style: none;
}
.review a.icon:link, .content-body a.icon:link {
	border-style: none;
}
.review a.icon:visited, .content-body a.icon:visited {
	border-style: none;
}
.review a.icon:hover, .content-body a.icon:hover {
	border-style: none;
}
.review a.icon:active, .content-body a.icon:active {
	border-style: none;
}
.review a.icon:focus, .content-body a.icon:focus {
	border-style: none;
}
/*.review--prime p:first-of-type:first-letter, .content--prime p:first-of-type:first-letter {
	margin-top: 6px !important;
	font-size: 30px !important;
	line-height: 45px !important;
	font-weight: normal !important;
	font-family: Arial, Helvetica, sans-serif !important;
}
.content--prime p:first-of-type:first-letter {
	padding: 0 14px;
	margin-top: 5px !important;
}
*/
/*.content--prime p:first-of-type:first-letter, .review--prime p:first-of-type:first-letter {
	float: left;
	margin-right: 3px;
	margin-top: 6px;
	border-radius: 45px;
	padding: 0px 15px;
	background-color: #20b8ec;
	font-size: 40px;
	font-weight: normal;
	line-height: 63px;
	color: white;
	text-transform: uppercase;
}
*/
.review-body > p, .content-body > p {
	font-size: 17px !important;
	line-height: 1.65;
	color: #575757;
}
//.review-body > ul li, .content-body > ul li {
//	font-size: 17px !important;
//	margin-bottom: 8px !important;
//	color: #ED7966;
//	line-height: 1.65;
//}
/* governs bulleted lists */
/*.review-body >4 li, .content-body > ol li {*/
	/*font-size: 17px !important;*/
	/*margin-bottom: 8px !important;*/
	/*color: #ED7966;*/
	/*line-height: 1.65;*/
/*}*/
.content-body ol > li:before {
content: counter(ca-counter);
counter-increment: ca-counter;
color: #ED7966;
text-decoration: none;
position: absolute;
left: 14px;
font-weight: 700;
font-size: 17px;
margin-bottom: 8px;
line-height: 1.65;
}
/* governs numbered lists */
.featuredList > li {
	font-size: 17px !important;
	margin: 0 0 14px 6px;
	position: relative;
	padding-left: 15px;

	&:before {
		position: absolute;
		left: 0;
	}
}
.review-body > ul li span.c, .content-body > ul li span.c {
	color: #4E4E4E;
}
.numberedList-item {
	color: #ED7966 !important;
	margin-bottom: 6px;
	line-height: 1.65;
	font-style:normal !important;
}
.numberedList-text {
	color: #4E4E4E !important;
}
.userAvatar-name {
	font-size: 15px !important;
	font-weight: bold;
}
.review-body img, .content-body img {
	border: 1px solid #575757;
}
.pageHeader-header, .headline-title, .importantData-value, .quotations p {
	font-family: 'Quicksand', sans-serif;
	letter-spacing: -0.10em;
}
body {
	color: #4E4E4E !important;
}
.headline-title {
	border-bottom: 1px solid $grey;
	padding-bottom: 4px;
}
.headline-excerpt {
	font-size: 22px;
	font-weight: bold;
	color: #0CB2EB;
	text-transform: uppercase;
	letter-spacing: 0;
	font-style: normal;
}/* for OUR REVIEW */
h1, .h1 {
	font-size: 33px;
	font-weight: 300;
	letter-spacing: -0.02em;
	margin-top: 0px;
	margin-bottom: 10px;
	color: #2E2D2D;
} /* for subheads */
h3, .h3 {
	font-size: 33px;
	font-weight: normal;
	letter-spacing: -0.02em;
	margin-top: 30px;
	margin-bottom: 10px;
	color: #3D3C3C;
} /* for subheads */
h4, .h4 {
	font-size: 24px;
	font-weight: 600;
	letter-spacing: -0.02em;
	margin-top: 20px;
	margin-bottom: 10px;
	color: #4E4E4E;
} /* for subheads */
.review-body h3, .content-body h3 {
	font-size: 33px;
	font-weight: normal;
	color: #3D3C3C;
	line-height: 1.2;
	letter-spacing: -0.02em;
	margin: 30px 0 10px;
} /* for review subheads */
.headline-excerpt {
	/*margin-top: 20px;*/
	margin-top:10px;
	margin-bottom: -60px;
	padding-bottom: 30px;
	/*border-top: 1px solid #A3A2A2;*/
	padding-top: 4px;
	font-size: 22px;
	font-weight: normal;
	color: #0CB2EB;
	text-transform: uppercase;
	letter-spacing: 0;
	font-style: normal;
}/* for Our Review logo */
.headline-title {
	font-size: 50px;
	font-weight: 500;
	line-height: 1.2;
	color: #0cb2eb;
	padding-bottom:10px; /*added by dcgws*/
}/* for main blue headlines */
.review-subtitle, .highlightBG-sectionHeader, .importantData-title, .quotations-title, .review h2 {
	margin-top: 48px;
	margin-bottom: 28px;
	font-size: 33px;
	line-height: 1.16;
	font-weight: normal;
	clear: left;
}/* for top highlights & top dining subheads */
.featuredImages-title {
	margin-top: 15px;
	font-size: 22px;
	font-weight: bold;
	color: #404040;
}/* for thumbnail dining subheads */
.dropcap {
	-x-system-font: none;
	color: #0CB2EB;
	float: left;
	font-family: "Open Sans", sans-serif;
	font-size: 54px;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 56px;
	margin-right: 3px;
}
.dropcap2 {
	color: #0CB2EB;
	float: left;
	font-family: "Open Sans", sans-serif;
	font-size: 34px;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 49px;
	margin: -4px 9px 0 0;
	padding: 0px 0 -3px 0;
	border-bottom: 3px solid #0CB2EB;
}
.dropcap3 {
	color: #0CB2EB;
	float: left;
	font-family: "Open Sans", sans-serif;
	font-size: 27px;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-variant: normal;
	font-weight: bold;
	line-height: 40px;
	margin: -3px 12px 0 0;
	padding: 0px 0 8px 0;
	border-bottom: 4px solid #0CB2EB;
}
.dropcap10 {
	-x-system-font: none;
	color: #0CB2EB;
	float: left;
	font-family: "Open Sans", sans-serif;
	font-size: 48px;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 50px;
	margin-right: 3px;
}
.pullquote {
	font-family: "Open Sans", sans-serif;
	font-size: 1.5em;
	color: #0CB2EB;
	display: inline;
	line-height: 200%;
	float: right;
	width: 280px;
	border-left: 3pt solid #0CB2EB;
	margin: 18px 0 8px 20px;
	padding: 0px 0 6px 12px;
	letter-spacing: -0.04em;
}
.pullquote2 {
	font-family: "Open Sans", sans-serif;
	font-size: 1.5em;
	color: #0CB2EB;
	display: inline;
	line-height: 200%;
	float: left;
	width: 280px;
	border-right: 3pt solid #0CB2EB;
	margin: 18px 20px 8px 0;
	padding: 6px 12px 6px 0;
	letter-spacing: -0.04em;
}
.tooltip {
	position: absolute;
	z-index: 1030;
	display: block;
	font-size: 16px;
	line-height: 1.4;
	visibility: visible;
	filter: alpha(opacity=0);
	opacity: 0;
}
.tooltip.in {
	filter: alpha(opacity=97);
	opacity: .97;
}
.tooltip.top {
	padding: 5px 0;
	margin-top: -3px;
}
.tooltip.right {
	padding: 0 5px;
	margin-left: 3px;
}
.tooltip.bottom {
	padding: 5px 0;
	margin-top: 3px;
}
.tooltip.left {
	padding: 0 5px;
	margin-left: -3px;
}
.tooltip-inner {
	width: 400px;
	min-width: 400px;
	padding: 30px;
	color: #303030;
	text-align: left;
	line-height: 1.5;
	text-decoration: none;
	background-color: #f7f7f7;
	border-radius: 4px;
	border: 1px solid #A6A2A2;
}
.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #f7f7f7;
}
.tooltip.top-left .tooltip-arrow {
	bottom: 0;
	left: 5px;
	border-width: 5px 5px 0;
	border-top-color: #f7f7f7;
}
.tooltip.top-right .tooltip-arrow {
	right: 5px;
	bottom: 0;
	border-width: 5px 5px 0;
	border-top-color: #f7f7f7;
}
.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #f7f7f7;
}
.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #f7f7f7;
}
.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #f7f7f7;
}
.tooltip.bottom-left .tooltip-arrow {
	top: 0;
	left: 5px;
	border-width: 0 5px 5px;
	border-bottom-color: #f7f7f7;
}
.tooltip.bottom-right .tooltip-arrow {
	top: 0;
	right: 5px;
	border-width: 0 5px 5px;
	border-bottom-color: #f7f7f7;
}
.highlightBG {
	margin-top: 20px;
}
.highLightText {
	text-transform: uppercase;
	font-size: 21px;
	font-weight: normal;
	margin: 0 0 20px 0;
	padding: 0;
}
.review-subtitle, .highlightBG-sectionHeader, .importantData-title, .quotations-title, .review h2 {
	margin-top: 29px !important
}
.title, .importantData-value, .quotations p {
	font-family: 'Quicksand', sans-serif;
	letter-spacing: -0.08em;
}
.agate {
	margin: 0;
	padding: 0;
	font-family: "Open Sans", sans-serif;
	color: #111;
	font-size: 12px;
	font-height: 1.5 sans-serif;
}
a.blue_button {
	font-size: 20px;
	font-weight: bold !important;
	font-family: 'Open Sans', Arial, sans-serif;
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	display: block;
	padding: 8px 24px 8px 24px;
	margin: 0 auto;
	border-radius: 10px;
	-o-border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	text-shadow: -1px -1px 1px rgba(0, 0, 0, .2);
	-o-text-shadow: -1px -1px 1px rgba(0, 0, 0, .2);
	-moz-text-shadow: -1px -1px 1px rgba(0, 0, 0, .2);
	-webkit-text-shadow: -1px -1px 1px rgba(0, 0, 0, .2);
	box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.2);
	width: 125px;
	margin-top: 20px;
}
a.blue_button:link, a.blue_button:visited {
	background: #88b633;
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91bb35', endColorstr='#548a26');
	background: linear-gradient(#91bb35, #548a26);
	background: -o-linear-gradient(#91bb35, #548a26);
	background: -moz-linear-gradient(#91bb35, #548a26);
	background: -webkit-gradient(linear, left top, left bottom, from(#91bb35), to(#548a26));
	background: -webkit-linear-gradient(#91bb35, #548a26);
}
a.blue_button:hover {
	color: #fff;
	background: #88b633;
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d2e7a2', endColorstr='#91bb35');
	background: linear-gradient(#d2e7a2, #91bb35);
	background: -o-linear-gradient(#d2e7a2, #91bb35);
	background: -moz-linear-gradient(#d2e7a2, #91bb35);
	background: -webkit-gradient(linear, left top, left bottom, from(#d2e7a2), to(#91bb35));
	background: -webkit-linear-gradient(#d2e7a2, #91bb35);
}
.topten {
	float: left;
	border-top: 1px #737373 solid;
	padding-top: 3px;
	padding-bottom: 10px;
}
.topten-left {
	width: 22%;
	float: left !important;
	font-weight: bold;
	padding: 20px 10px 3px 0;
}
.topten-right {
	width: 78%;
	padding: 9px 3px 8px 0 !important;
	margin: 0 !important;
	float: right;
}
.topten-right p {
	font-size: 16px;
	line-height: 26px;
	padding: 0 3px 8px 0 !important;
	margin: 0 !important;
	color: #4E4E4E;
}
.topten-bump {
	font-size: 21px;
	font-weight: bold;
	color: #4E4E4E;
	line-height: 25px;
	padding: 0px !important;
	margin: 0px !important;
}
.topten-bump2 {
	font-size: 17px;
	font-weight: normal;
	line-height: 22px;
	padding-bottom: 6px;
}
a img {
	border: 0;
}
.tooltip-inner {
	width: 400px;
	min-width: 400px;
}
.ratingBlock-label {
	padding: 7px 5px;
	font-size: 15px;
	line-height: 1.3;
	font-weight: 600;
	color: #7b7b7b;
}
.ratingBlock-label span {
	color: #575757 !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: normal !important;
	line-height: 24px !important;
	letter-spacing: -0.02em !important;
}
div.clear {
	clear: both;
}
.btn {
//	padding: 9px 27px;
//	font-size: 21px;
//	line-height: 21px;
//	border-radius: 6px;
}
img.nob {
	border:none !important;
}
ul.nopad {
	padding-bottom:0px !important;
}
.spacing {
	font-family:Arial;
	line-height: 12px;
	padding-bottom: 12px;
}
.spacing6 {
	font-family: Arial;
	line-height: 6px;
	padding-bottom: 6px;

}
.spacing3 {
	font-family:Arial;
	line-height: 3px;
	padding-bottom: 3px;
}
.btn-large .btn--bookIt {
	margin-top: 12px;
}
.aboutUser-subheader {
	color: #3d3c3c;
	line-height: 1.2;
	letter-spacing: -0.04em;
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0px;
}
.aboutUser-text {
	color: #5e5e5e;
	font-size: 14px;
	line-height: 1.4;
	letter-spacing: 0;
	padding-top:6px;
}
.footer-map-links {
	font-size: 15px;
	color: #14323c;
	font-weight: 600;
	letter-spacing: 0em;
	line-height: 34px;
}
.btn--dropdown {
	padding: 5px 12px;
	font-size: 18px;
	line-height: 19px;
	border-radius: 6px;
	color: #757475;
	background-color: #F2F3F5; 
	border-color: #E0E0E0;  
	text-align: left;
	text-transform: none;
}
.btn--dropdown:hover, .btn--dropdown:focus, .btn--dropdown.focus, .btn--dropdown:active, .btn--dropdown.active, .open > .btn--dropdown.dropdown-toggle {
	color: #757475;
	background-color: #E8E8E8;
	border-color: #c1c1c1;
}
.btn--primary:hover, .btn--primary:focus, .btn--primary.focus, .btn--primary:active, .btn--primary.active, .open > .btn--primary.primary-toggle {
color: #ffffff;
background-color: #05CDF5; 
border-color: #0cb2eb;
}
.subhead h4 {
	font-weight: normal;
	font-size: 21px;
	color: #3d3c3c;
	line-height: 26px;
	letter-spacing: 0;
	margin-bottom: -20px;
	clear: left;
}
.topBar-shortcuts-nav a:active {
	color: #0cb2eb;
	text-decoration:none !important;
}
.topBar-shortcuts-call {
	font-size: 14px;
	}
.topBar-filters-link {
	font-size: 18px;
	font-weight: bold;
	letter-spacing: -0.02em;
	color: #222222;
	line-height: 25px;
}

.cruiseSearch-nav-link {
font-size: 14px;
font-weight: bold;
letter-spacing: -0.02em;
color: #000000;
line-height: 25px;
}
.authorMeta-description {
font-size: 15px;
color: #6d6d6d;
}
.slider-caption {
position: absolute;
bottom: 0;
width: 100%;
padding: 10px 0 10px;
background-color: rgba(0, 0, 0, 0.5);
font-size: 14px;
color: #ffffff;
letter-spacing: 0.04em;
font-weight: normal;
}
.slider-caption-author {
text-align: right;
font-size: 11px;
}
.ratingBlock {
margin-top:10px;
background-color: #F2F2F2;
-webkit-box-shadow: 0px 1px 5px 0px rgba(247, 247, 247, 0.75);
box-shadow: 0px 1px 5px 0px rgba(247, 247, 247, 1);
}
 .searchPage-headline {
background-color: #0cb2eb;
font-weight: normal;
letter-spacing: -0.02em;
color: #ffffff;
font-size: 21px;
padding: 0 0 0 20px;
line-height: 36px;
}
.userNav {
border: 2px solid #c9c9c9;
border-radius: 6px;
background-color: #FAFAF7;
padding:4px 10px 4px;
}
/* TEAM PAGE */
.team-page {
color:#262626 ;display:block;width:1024px;margin:0 auto;
}
.team-page .heading {
text-align:left;width:63%;margin:54px auto 51px;
}
.team-page .heading h2 {
font-family:"Open Sans";font-weight:bolder;font-size:36px;color:#303030;letter-spacing:-0.04em;margin:0px 0 8px;
}
.team-page .heading p {
font-family:"Open Sans";font-size:17px;color:#4f4f4f;line-height:1.8;margin:0 0 24px;
}
.team-page .staff-container {
padding:0px 40px 0px;
}
.team-page .staff-container .staff{
width:220px;display:inline-block;margin:0px 13px 50px 0px;
}
.team-page .staff-container .staff a {
color:#696969;text-decoration:none;
}
.team-page .staff-container .staff a:hover{
text-decoration:underline;
}
.team-page .staff-container .staff .staff-image {
width:220px;
}
.team-page .staff-container .staff .staff-image img {
width:100%;height:auto;
}
.team-page .staff-container .staff-info > div{
margin-bottom:5px;
}
.team-page .staff-container .staff .staff-info .staff-name{
font-family:"Open Sans";font-weight:bolder;font-size:18px;color:#303030;letter-spacing:-0.04px;border-bottom:4px #1ccaed solid;padding-bottom:4px;
}
.team-page .staff-container .staff .staff-info .staff-position {
font-family:"Open Sans";font-size:14px;color:#696969;
}
.team-page .staff-container .staff .staff-info .staff-location {
font-family:"Open Sans";font-size:14px;color:#696969;
}
.team-page .staff-container .staff .staff-info .staff-social {
font-family:"Open Sans";font-size:14px;color:#696969;
}
.team-page .staff-container .staff .staff-info .staff-about {
font-family:"Open Sans";font-size:14px;color:$ca-blue;
}
.team-page .staff-container .staff .staff-info .staff-social .staff-social-icon {
width:14px;height:auto;vertical-align:middle;
}
.slider-caption {
position: absolute;
bottom: 0;
width: 100%;
padding: 10px 0 10px;
background-color: rgba(0, 0, 0, 0.5);
font-size: 14px;
color: #ffffff;
letter-spacing: 0.04em;
line-height: 1.5;
font-weight: normal;
}
/* ITINERARY pages */
.cruiseIndexPage-cruise-name {
margin: 0px;
margin-bottom: 5px;
font-size: 24px;
letter-spacing: -0.04em;
line-height: 30px;
font-weight: 600;
text-transform: capitalize;
}
.cruiseIndexPage-cruise-route {
margin: 0px;
font-size: 16px;
letter-spacing: -0.04em;
line-height: 26px;
color: #5c5c5c;
}
.cruiseIndexPage-table-head {
vertical-align: bottom;
color: #303030;
font-size: 16px;
font-weight: normal;
}
.cruiseIndexPage-table-price {
text-align: center;
vertical-align: top;
color: #0cb2eb;
/*font-size: 30spx;*/
letter-spacing: -0.04em;
}
.cruiseIndexPage-infoLink-btn {
font-size: 21px;
font-weight: 600;
padding: 16px 30px;
}
/* end Itinerary pages */
.package h2 {
	font-size: 30px;
	line-height: 36px;
	font-weight: normal;
	margin: 0 !important;
	padding: 16px 0 12px 0 !important;
	letter-spacing: 0 !important;
	color: #3d3c3c;
}
/*.port-table { border:1px solid #ccc; width="750px" } */
.itinerary-header{ font-size:18px; text-transform: uppercase; font-weight:400; padding-top:6px; padding-bottom:0px;padding-left:0px !important;margin-left:0px;}
.itinerary-content { font-size:14px; text-transform: uppercase; }
.itinerary-list { width:40px; display: inline-block; font-size:14px !important; padding-left:20px; padding-top:6px; padding-bottom:6px;}
.itinerary-list-header { padding-bottom:10px; font-weight:700;}
.itinerary-header div:nth-child(2) { width:140px; font-size:14px !important;}
.itinerary-content div:nth-child(2) a { color:#0cb2eb; font-size:14px !important;}
.itinerary-header div:nth-child(3) { width:320px;font-size:14px !important; }
.itinerary-header div:nth-child(3) a { color:#0cb2eb; font-size:14px !important;text-decoration:none !important;}
.itinerary-header div:nth-child(4) { width:100px;font-size:14px !important; }
.itinerary-header div:nth-child(4) a { color:#0cb2eb; font-size:14px !important;}
.itinerary-header div:nth-child(5) { width:100px;font-size:14px !important; }
.itinerary-header div:nth-child(5) a { color:#BAE6F5; font-size:14px !important;}
.itinerary-header div.itinerary-content:nth-child(odd) {background: #F2EDE1; font-size:14px !important;}
.itinerary-header div.itinerary-content:nth-child(even) {background: #FFF}

/* governs color chart on package pages */
.itinerary-header div.itinerary-content:nth-child(odd) {
background: #F2EDE1; 
}
/* tan color tint */
.itinerary-content div:nth-child(2) a {
width: 300px;
font-size:14px !important;
color: #0cb2eb;
text-decoration:none !important;
}
.itinerary-content div:nth-child(2) a:visited {
font-size:14px !important;
text-decoration:none !important;
color: #BAE6F5;
}
.review-body img, .content-body img {
border: 1px solid #B3AB9D;
}
.authorMeta--withThumbnail {
margin-bottom: 50px;
border-top: 1px solid #0cb2eb;
padding-top: 20px;
}
.authorMeta-description {
font-size: 15px;
line-height:1.6;
color: #6d6d6d;
}
.userAvatar--inPost .userAvatar-name {
font-size: 14px;
margin-bottom: 4px;
margin-top:8px;
}
.footer-copyright {
background-color: #222;
}
.bookNow-title {
color: #0cb2eb;
font-family: "Quicksand", sans-serif;
font-size: 36px;
letter-spacing: -0.10em;
padding-top:12px;
}
.routeTable-location-description {
font-size: 15px;
line-height: 24px;
color:#5C5C5C; 
}
.routeTable-location-name {
margin-top: 0px;
font-size: 24px;
font-weight: 500;
letter-spacing: -0.02em;
color:#5C5C5C; 
}
.routeTable-head {
height: 32px;
border-top: 1px solid #cccccc;
border-bottom: 1px solid #cccccc;
padding-top: 5px;
}
.routeTable-head-desc {
color: #8C8C8C;
font-weight:bold;
display: inline-block;
font-size: 14px;
}
.routeTable-list-link:hover {
background-color: #F2EDE1; 
}
.sectionSelector .dropdown-menu > li > a {
padding: 3px 10px;
font-size: 18px;
color: #363636;
}
.btn--dropdown:hover, .btn--dropdown:focus, .btn--dropdown.focus, .btn--dropdown:active, .btn--dropdown.active, .open > .btn--dropdown.dropdown-toggle {
color: #757475;
background-color: #f5f5f5;
border-color: #c1c1c1;
}
.reasonList-header {
font-size: 21px;
letter-spacing: -0.02em;
font-weight: 500;
}
.image-caption {
font-weight: 600;
font-size: 14px;
line-height: 21px;
padding-top:4px;
display: block;
}
/*> p {*/
/*font-size: 17px !important;*/
/*text-align: left !important;*/
/*line-height: 1.65;*/
/*color: #575757;*/
/*}*/
/* notification that image upload worked */

.qa-recent-question {
margin-bottom: 2px;
color: #393B3B;
}
.qa-recent-question, .qa-founded-question, .qa-single-question {
font-size: 21px;
line-height: 1.5;
font-weight: 600;
}
.qa-ask-button {
font-size: 18px;
font-weight: 600;
text-transform: capitalize;
}
.cruiseIndexPage-table-head {
vertical-align: top;
color: #303030;
font-size: 12px;
line-height: 18px;
font-weight: 600;
}
.image-credits {
font-weight:normal;
}
