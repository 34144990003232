.callbackForm{
  &-title {
    font-weight: 500;
    font-size: 27px;
    text-align: center;
    margin-bottom: 15px;
  }
  &-subtitle{
    font-size: 12px;
    text-transform: uppercase;
  }
  &-section:nth-child(2){
    border-left: 1px solid gainsboro;
  }

  &-contactWay-input{

  }

  &-contactWay-label{
    margin-right: 16px;
    vertical-align: middle;
    text-transform: uppercase;
  }

  &-input{
    margin-bottom: 15px;
  }

  &-labelSpecial{
    color: #858585;
  }

  &-lengthInput{
    font-size: 12px;
  }

  .control-label{
    display: none;
  }

  .form-control {
    @extend %formElement;
  }

  .form-control.required {
    @include placeholder {
      &:after {
        content: "*";
        color: #f72a4c;
      }
    }
  }

  span.required{
    color: #f72a4c;
  }


  &-btnWrapper{text-align: center}
  &-btn{
    margin-top: 15px;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.callbackForm-lessSpace{
  padding-left: 0px;
}

.callbackForm-inline{
  padding: 0px;
  margin-left: -15px;
}

.callbackForm-passengers{
  float: left;
  width: 50%;
  &-label{
    margin-top: 30px;
  }
}

.callbackForm-kids{
  .ui-increment-count {
    background-color: #baddf5;
  }
}