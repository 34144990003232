.ratingBlock{
  background-color: #f8f8f8;
  @include box-shadow(0px 1px 5px 0px rgba(247, 247, 247, 0.75));

  &--besideReview{
    padding: 1px 15px 15px;
  }

  &-title, h2{
    margin-bottom: 35px;
    font-size: 19px;
    color: #525252;
    text-align: center;
  }

  &-table{

    tr > th{
      text-align: center;
      font-size: 12px;
      color: #878787;
    }

  }

  &-label{
    padding: 7px 5px;
    font-size: 15px;
    font-weight: 600;
    color: #7b7b7b;
  }

  &-rating{
    padding: 7px 5px;
    vertical-align: bottom;
  }

  &-linkContainer{
    text-align: center;

    &-link{
      font-size: 13px;
      color: $cruiseable-blue;
    }
  }

  &-awardsList, ul{
    @extend .list-unstyled;
    font-style: italic;
    font-size: 14px;
    color: #767676;
    > li {
      margin: 15px 0px;
    }
  }

  &-tooltip{
    padding-top: 14px;
    .tooltip-inner {
      width: 600px;
      min-width: 600px;
    }
  }

  &-table--inReview{
    width: 100%;

    .ratingBlock-rating{
      text-align: center;
    }

  }



}