.stashing{
  position: relative;

  &-message{
    position: absolute;
    top: -42px;
    left: 50%;
  }
  &-message-text{
    position: relative; left: -75px; top:0px; z-index: 1025;
    border-radius: 50%; border: 1px #cccccc solid; padding: 14px 4px;
    width: 100px; height: 100px;
    background-color: rgba(255,255,255, 0.95);
    font-size: 14px; font-weight: 600; color: $ca-red; text-align: center;
  }
  // &-message-text.ng-hide-add.ng-hide-add-active{
  //   @include transform(scale(0));
  //   @include transition(all ease-out 0.2s)
  // }
  // &-message-text.ng-hide-remove.ng-hide-remove-active{
  //   @include transform(scale(1));
  //   @include transition(all ease-out 0.2s)
  // }
  // &-message-text.ng-hide{
  //   @include transform(scale(0));
  // }

}
@include fade_zoom('.stashing-message-text');