.shareImage{
  padding: 20px;
  text-align: center;

  &-title{
    font-size: 27px;
    font-weight: 600;
    color: $ca-blue;
    margin: 10px 0 30px;
  }
  &-uploadLink{
    display: block;
    text-decoration: none;
    cursor: pointer;
  }
  &-image{ margin-bottom: 15px; }
  &-legend{ font-size: 18px; color: $ca-blue }
  &-legend:hover{ text-decoration: none}

  &-alter{ font-size: 16px; text-transform: uppercase }
  &-optionLegend{ font-size: 16px; color: $ca-blue }
}