.cruiseIco{
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: text-top;

  &-dashboard{
    @include size(35px 35px);
    vertical-align: text-top;
    background-image: url(../img/common/bookmark_icon.png);
    &:hover{
      background-image: url(../img/common/bookmark_icon.png);
    }
  }


  @each $icon in bookmark, bookmark_25, heart, more {
    &-md-#{$icon} {
      cursor: pointer;
      @include size(45px 30px);
      background-position: 50% 50%;
      background-image: url('../img/common/medium/#{$icon}_icon.png');
    }
  }

  @each $icon in bookmark, bookmark_25, heart{
    a:hover &-md-#{$icon},
    .active &-md-#{$icon}{
      background-image: url('../img/common/medium/#{$icon}_icon_filled.png');
    }
  }

  @each $icon in calendar, globe, money, settings {
    &-#{$icon} {
      @include size(22px 21px);
      background-image: url('../img/common/#{$icon}_icon.png');
    }
  }

  &-md-label{
    vertical-align: sub;
    a &, a:hover &{
      color: #ffffff;
    }
    a:hover > &{
      text-decoration: underline;
    }
  }
  &-md-label--white{
    color: #ffffff;
  }

  &-envelope{
    vertical-align: middle;
    @include size(24px 15px);
    margin: 0 2px;
    background-image: url('../img/common/envelope.png');
  }

}

.starRating{
  display: inline-block;
  text-align: center;
  vertical-align: text-bottom;

  &-ico{
    @include size(10px 20px);
    display: inline-block;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url("../img/common/star_active.png");
    float: left;
  }
  &-ico--half{
    @extend .starRating-ico;
    background-position: right top;
  }
  &-ico--inactive{
    @extend .starRating-ico;
    background-image: url("../img/common/star_inactive.png");
  }
  &-ico--inactive--half{
    @extend .starRating-ico--inactive;
    background-position: right top;
  }

}