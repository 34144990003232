.listOfLinks{
  text-align: left;
}
.listOfLinks-caption{
  margin: 0px 0px 5px;
  text-align: left;
}
.listOfLinks-nav{}
.listOfLinks-item{
  color: #808080;
  font-size: 12px;
  &:hover{
    color: #1C3434;
    text-decoration: none;
  }
}