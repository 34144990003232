.cruiseSearch-filters-date{
  background-color: $ca-blue;
  @include clearfix;
  text-align: left;
  padding: 30px 10px 30px;

  &-header{
    color: #ffffff;
    margin-top: 0px;
    font-size: 27px;
    letter-spacing: -0.02em;
    margin-left: 20px;
  }
  &-header > i{margin-right: 10px}

  &-year{
    color: #ffffff;
    margin-left: 4px;
    font-size: 27px;
    letter-spacing: -0.02em;

  }

  &-monthList{
    list-style: none;
    padding: 0px;
    max-width: 305px;
  }

  &-month{
    display: block;
    float: left;

  }

  &-month-label{
    display: block;
    margin: 5px 4px;
    border: 1px solid #ffffff;
    padding: 4px 0px;
    width: 60px;
    font-size: 14px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    &:hover{
      color: #ffffff;
    }

    &.active{
      background-color: #ffffff;
      color: $ca-red;
    }

    &.available{
      border: 2px solid #ffffff;
    }

    &.disabled{
      cursor: default;
      opacity: 0.5;
      &:hover{ text-decoration: none }
    }
  }

  &-done{
    float: right;
    color: #ffffff;
    &:hover{color: #ffffff;}
  }



}