.cruiseFilter-size {
  margin-bottom: 20px;

  &-item {
    border-bottom: 1px solid $ca-grey;
    cursor: pointer;
  }
  &-item:last-child {
    border-bottom-width: 0;
  }
  &-item.active {
    background-color: #baddf5
  }

  &-label{
    display: inline-block;
  }

  &-name {
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #545454;
  }
  &-detail {
    color: #757575;
    font-size: 12px;
  }

  &-ico {
    display: inline-block;
    @include size(70px 20px); 
    content: "";
    background: no-repeat 50% 50%;

    @each $icon in intimate, small, medium, large, mega {
      &--#{$icon} {
        background-image: url('components/cruise-search/cruise-search-filters-size/ship-#{$icon}.png');
      }
    }
  }

}