.highlightBG{
  background-color: #f8f8f8;
  padding-bottom: 20px;

  &--black{
    background-color: #000000;
  }

  &--relative{
    position: relative;
  }

  &-sectionHeader{
    @extend .review-subtitle;
  }

  &--off{
    background-color: #ffffff;
  }

  &--spacer{
    padding: 20px 0px;
  }


}
.highlightBG.highlightBG--noSpacer{
  margin: 0px;
}