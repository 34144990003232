.searchPage{
  &-title{
    @extend %quicksand;
    font-size: 36px;
    letter-spacing: -0.08em;
    color: #4B4B4D;
    text-align: center;
  }
  &-headline{
    background-color: $cruiseable-blue;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #ffffff;
    font-size: 24px;
    padding-left: 10px;
    line-height: 36px;
  }
  &-noResults{
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    margin-top: 0px;
  }
}

.searchPage-results-post{
  border-bottom: solid 1px #e5e5e5;
  padding-bottom: 24px;
  margin: 24px 0px;

  &-img{}
  &-heading{
    margin-top: 0px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: -0.04em;
    color: #303030
  }

  &-content{
    font-size: 14px;
    line-height: 20px;
    color: #5c5c5c;
  }
  &-content-more{
    font-weight: bold;
    color: $cruiseable-blue;
  }
}

.searchPage-results-cruiseItem{
  width: 33%;

  &-img{}
  &-heading{}
  &-content{}
  &-content-more{}
}

.searchPage-rating{
  bottom: 10px;
}

.searchPage-userContributions{
  margin: 0px -10px;
  padding-bottom: 40px;
}

.searchPage-images{
  margin: 0px -10px;
  padding-bottom: 40px;
}

.searchPage-moreLoader{
  margin-bottom: 40px;
}