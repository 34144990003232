.subscribeForm{
  text-align: center;

  @include input-size(".subscribeForm-input", 35px, 6px, 6px, 18px, 22px, 6px);
  &-input{
    @include placeholder{
      color: #c7c7c7;
    }
    border: 1px solid #B3B3B3;
    background-color: #f9f9f9;
    color: #B3B3B3;

    &--underAvatar{
      width: 300px;
      margin-top: 15px;
      margin-right: 10px;
    }
  }
}

.subscribe{
    background: url("../img/subscribe-form/email-sign-up-form.jpg") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 600px;
    padding: 30px;
    border-radius: 6px;

  &-heading{
    color: #FFFFFF;
    text-align: right;
    font-size: 54px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 300;
  }

  &-legend{
    font-weight: 300;
    color: #FFFFFF;
    font-size: 24px;
    text-align: left;
    width: 180px;
    float: right;
    line-height: 30px;
  }

  &-form{
    margin-top: 60px;
    margin-bottom: 12px;
    min-height: 108px;
  }

  &-input input.form-control{
    border: 0;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 300;

    @include placeholder {
      text-transform: uppercase;
      text-align: center;
    }
  }

  &-formEmailWrapper{
    margin-top: 10px;
  }

  &-inputEmail{
    .btsForm-inputWrap{
      position: relative;
    }
    input.form-control{
      padding-right: 100px;
    }
  }

  &-btn{
    @include button-variant(#ffffff, #f58340, #f58340);
    @include button-size(2px, 14px, 16px, 24px, 16px);
    position: absolute;
    top: 2px;
    right: 2px;
  }

  &-agreement{
    margin-top: -18px;
    position: relative;
    color: #ffffff;
    text-align: center;
    input {
      margin-left: -20px;
    }
  }

  &-disclaimer{
    color: #FFFFFF;
    text-align: center;
  }

  .btsForm-errorsList{
    position: absolute;
    top: -24px;
    left: 28px;
  }


  .subscribe-agreement{
    .btsForm-errorsList{
      position: absolute;
      top: 14px;
      left:  47%;
    }
  }


}