@mixin f($s: null, $w: null, $a: null, $f: null, $lh: null, $c: null) {
  @if $s{ font-size: $s; }
  @if $w{ font-weight: $w; }
  @if $a{ text-align: $a; }
  @if $f{ font-family: $f; }
  @if $lh{ line-height: $lh; }
  @if $c{ color: $c; }
}

@mixin b($w: 1px, $c: $grey, $r:$radius, $s: solid) {
  border-width: $w;
  border-color: $c;
  border-style: $s;
  border-radius: $r;
}

@mixin c($c: null, $b: null) {
  @if $c{ color: $c; }
  @if $b{ background-color: $b;}
}

@mixin a($c, $h: $c, $d: none) {
  color: $c;
  text-decoration: $d;
  &:hover{ color: $h }
}

@mixin pos($p: null, $t: null, $r: null, $b: null, $l: null, $i: null){
  @if $p{ position: $p };
  @if $t{top: $t};
  @if $r{right: $r};
  @if $b{bottom: $b};
  @if $l{left: $l};
  @if $i{ z-index: $i };
}

@mixin lt_corner($i: 1, $t:5px, $l:10px){
  @include pos($pa, $i); top: $t; left: $l;
}

@mixin display_on_hover ($trigger) {
  display: none;
  #{$trigger}:hover & {display: block}
}

@mixin fade_zoom ($selector) {
  #{$selector}.ng-hide-add.ng-hide-add-active{
    @include transform(scale(0));
    @include transition(all ease-out 0.2s)
  }
  #{$selector}.ng-hide-remove.ng-hide-remove-active{
    @include transform(scale(1));
    @include transition(all ease-out 0.2s)
  }
  #{$selector}.ng-hide { @include transform(scale(0)); }
}

@mixin squared ($size) { max-width: $size; max-height: $size; }
@mixin circled () { border-radius: 50% }
@mixin rounded($radius: $radius) { border-radius: $radius }

@mixin cropped_thumb ($size) {
  @include pos($pr); @include size($size); display: block;
  overflow: hidden;

  img { @include pos($pa); left: 50%; top: 50%;
        @include size(auto 100%); @include transform(translate(-50%,-50%)); }

  img.portrait { @include size(100% auto); }
}

@mixin vertical_align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin vertically-center(){
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }
}

@mixin ol_enumerate {
  counter-reset: numList;
  & > li { position: $pr; }
  & > li:before { position: $pa;
    counter-increment: numList; content: counter(numList);
  }
}

@mixin multiline-ellipsis($height, $lineheight, $ellipsiswidth) {

$ellipsiswidth: 3em !default;

  .ellipsis {
    overflow: hidden;
    height: $height;
    line-height: $lineheight; }

  .ellipsis:before {
    content:"";
    float: left;
    width: 5px; height: $height; }

  .ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px; }

  .ellipsis:after {
    content: "\02026";

    float: right; position: relative;
    top: -25px; left: 100%;
    width: $ellipsiswidth; margin-left: -$ellipsiswidth;
    padding-right: 5px;

    text-align: right;
    background: white;
    background: -webkit-gradient(linear, left top, right top,
        from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
      background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
      background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
      background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
      background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white)
  }

}


@mixin cross_header($color, $bgColor, $width) {
  @include f($a: $c);
  position: relative;
  & > span { @include pos($pr, $i: 100); padding: 0px $width; @include c($b: $bgColor); }
  &:before {content: ""; @include pos($pa, 50%, $i: 100); display: block; @include size(100% 1px); @include c($b: $color); }
}

@mixin in_circle($ratio: 1.5em) { @include circled(); @include size($ratio);
                                  @include c(#fff, $ca-blue); @include f($a: $c, $lh: $ratio);}


@mixin sand_section() {
  @include b($c: $ca-sand-border, $r: 0px); @include c($b: $ca-sand);
  h1 { @include c($c: $ca-blue);}
 }

 @mixin sand_section_dark() {
  @include b($c: $ca-sand-dark-border, $r: 0px); @include c($b: $ca-sand-dark);
 }

@mixin font_section_I() {
 text-align: $c;
 h1 { @include f(1.8em, $ws) }
 p { @include f(1.1em) }
}

@mixin ca-button(){
  &-input { border-radius: 2em; border-right-width: 0px; height: 2em;
           @include f(1em, $wl, $c: $ca-color-placeholder); }
  &-input:focus { border-color: #ccc; @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075)); }
  &-addon { border-radius: 2em; border-left-width: 0px; padding: 1px 2px;
            @include f(1em, $a: $c); @include c($b: #fff) }
  &-button.btn { border-radius: 2em; padding: 0 1em; @include f(1em, $lh: 1.6em); }
}

