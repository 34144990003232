.caModal {
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
  background-color: #f5f5f5;
  background-color: rgba(0,0,0,0.75);
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background-color: rgba(0,0,0,0.85);

  &-table{
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  &-cell{
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    padding: 50px;
  }

  &-content {
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
  }

  &.ng-enter, &.ng-leave{
    -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  }

  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity:0;
  }

  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity:1;
  }

}

.modal-signIn {
  .modal-dialog { width: 780px; }
  .modal-content { padding: 20px; }
}