$topBar-height: 60px;
$topBar-logo-height: 46px;
$topBar-logo-margin: ($topBar-height - $topBar-logo-height)/2;
$topBar-border-color: $ca-black;

.topBar {
  border-bottom: solid $light-gray 1px;
  color: darken(#4E4E4E, 10%);
  &-logo {
    float: $l;
  }
  &-booking {
    @include f($a: $c);
  }

  &-bookingRow {
    margin-top: 6px;
    border-top: 1px solid $topBar-border-color;
    display: inline-block;
    .oobigo {
      color: #787878;
      a {
        color: #0cb2eb
      }
      .topBar-email{
        a{color: #0cb2eb}
      }
    }
  }
  &-bookHeader {
    margin: 0;
    @include f(1.2em, $ws)
  }
  &-phoneNumber {
    @include f(1.8em, $ws, $c: $ca-blue)
  }
  &-email {
    @include f(1.2em, $ws);
  }

  &-row {
    padding: 0.5em 0;
    border-top: $topBar-border-color 1px solid;
  }
  &-row.sticky {
    margin: 0 -15px;
    border-bottom: solid $light-gray 1px;
    padding: 0.5em 5px;
    width: 100%;
    @include c($b: #fff)
  }
  &-nav {
    margin-bottom: 0px;
    @include f(1.2em, $ws, $c, $lh: 1.4em);
    text-transform: uppercase;
  }
  &-nav > li {
    padding: 0 20px;
  }
  &-nav > li > a {
    @include ca-link
  }
  &-filters {
    float: $r;
    @extend .list-unstyled;
  }
  &-search {
    display: inline-block;
    max-width: 260px;
  }
  &-search .search-button {
    border-radius: 12px;
    padding: 4px 4px;
    @include f(12px, $lh: 12px);
  }
  &-search .dropdown-menu {
    min-width: 100%;
  }
  &-search .search-loader {
    right: 50px;
    top: 6px;
    font-size: 17px;
  }

}

.topBar-user {
  float: $r;
  @include f($a: $c);
  @include pos($pr);
  &--logged {
  }

  &-col {
    padding-left: 10px;
    display: table-cell;
    vertical-align: middle;
  }
  &-creditsVal {
    display: block;
    @include f(1.6em);
  }
  &-creditsLabel {
    display: block;
    @include f($c: $ca-blue, $lh: 1);
  }
  &-signInLink {
    line-height: $topBar-height;
  }
  &-avatar {
    max-width: 40px;
    display: inline-block;
    margin-right: 5px;
  }
  &-profileLink {
  }

  a {
    vertical-align: middle;
  }
}

.topBar-user-nav {
  @include pos($pa, 35px, 0px, $i: 1050);
  padding-top: 15px;
  width: 265px;
  display: none;
  @include f($a: $l);
  &:before {
    @include pos($pa, 9px, 17px);
    display: block;
    content: "";
    @include triangle(12px, #c9c9c9, up);
  }
  .topBar-user:hover & {
    display: block;
  }
}

.userNav {
  border: 5px solid #c9c9c9;
  border-radius: 10px;
  background-color: #f2f1eb;
  padding: 0px 10px;
  &-item {
    display: block;
    border-bottom: 1px solid #cfcdc6;
    padding: 6px 10px;
    @include f(1.4em, $c: $ca-blue)
  }
  &-item:last-child {
    border: 0px
  }
  &-tooltip > .tooltip {
    left: -135px !important;
  }
}

.topBar-editing {
  background-color: black;
}

.topBar-editing-nav {
  margin-bottom: 0px;
}

.topBar-nav-dropdown {
  background-color: $ca-blue;
  margin: 0;
  & > li > a {
    font-size: 1.2em;
    color: #fff
  }
  & > li > a:hover {
    color: $ca-blue
  }
}

.topBar-nav-dropdown-toggle {
  position: relative;
  display: block;
  /* dropdown position fix */
  padding-bottom: 2px;
  margin-bottom: -2px;
  /* fix end */
}

.topBar-nav-dropdown-toggle:hover {
  .topBar-nav-dropdown {
    display: block
  }
  & > a {
    color: $ca-blue
  }
}

.search-loader {
  position: absolute;
  color: $ca-blue;
  z-index: 3
}