.homeOld {
  .homePage{
    &-title{
      @extend %quicksand;
      margin-top: 36px;
      color: #0cb2eb;
      text-align: center;
      font-size: 33px;
      letter-spacing:-0.08em;
    }
    &-hearting{
      .hearting-message-text{
        top:-5px;
      }
    }
    &-searchSnippet{
      font-size: 16px;
      line-height: 28px;
      @extend %quicksand;
      letter-spacing: -0.06em;
      color: $ca-blue;
      text-align: center;
      margin-bottom: 30px;
    }
    &-searchSnippet-link{
      font-size: 18px;
      text-decoration: underline;
    }
    &-subscribe{
      margin-bottom: 50px;
    }

    .hearting-message-text{
      left: -23px;
      top: -23px;
    }

    .stashing-message-text{
      top: -23px;
    }

  }

  .homePage-slider{
    width: 100%;
    height: 800px;
    margin-bottom: 0px;


    &-container{
      margin-bottom: 40px;
      min-height: 839px;
    }
    &-actions{
      background-color: $gray;
      height: 50px;
    }
    &-actions-menu{
      color: #ffffff;
      margin: 10px 0px;
    }
    &-actions-menu-item{
      display: inline-block;
      margin-left: 20px;
      position: relative;
    }

    &-actions-btn.btn{
      @extend %openSans--light;
      font-size: 25px;
      border-radius: 0px;
      padding: 7px;
      position: relative;
      right: -15px;

    }

    &-indicators{
      text-align: center;

      > span{
        margin: 10px 3px;
        display: inline-block;
        height: 15px;
        width: 15px;
        border: 2px solid #b4b4b4;
        border-radius: 50%;
        &.active{
          background-color: $cruiseable-blue;
          border-width: 0px;
        }
      }
    }

  }
  .homePage-slide{
    &-image{
      height: 750px;
      background-size: cover;
      background-position: 50% 50%;
      position: relative;
    }

    &-caption {
      position: absolute;
      bottom: 70px;
      padding: 20px;
      background-color: rgba(0,0,0, 0.3);
      color: #ffffff;
      font-size: 18px;
      @extend %openSans--light;

      &-title { font-size: 50px; margin-top: 0px; font-weight: 300; color: #ffffff}
      &-subtitle { font-size: 18px; font-style: italic }
      &-price { @extend %openSans; text-transform: uppercase; font-weight: 400; }

      &--onRight{
        right: 0px;
      }
    }
  }

  .homePage-thumbnails{
    margin-top: 40px;
  }

  .homePage-thumbnail{
    padding-bottom: 40px;
    $thumbnailHeight: 170px;
    &:nth-child(4n+1){ clear: left; }

    &-imageWrapper{
      height: $thumbnailHeight;
      margin-bottom: 10px;
      overflow: hidden;
    }
    &-imageAlginer{
      height: $thumbnailHeight*2;
      top:-$thumbnailHeight/2 ;
      position: relative;
      &:before{
        height: 100%;
        vertical-align: middle;
        content: "";
        display: inline-block;
        margin-left: -4px;
      }
    }
    &-image{
      margin-bottom: 10px;
      display: inline-block;
    }
    &-icon{
      background: url("/static/img/home-page/blue_icon.png") no-repeat 50% 0px;
      width: 30px;
      min-height: 30px;
      float: left;
    }
    &-caption{
      margin-left: 30px;
    }
    &-caption-el{
      a:hover &{
        text-decoration: underline;
      }
    }
    &-title{
      margin-top:13px;
      @extend %openSans--bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.04em;
      color: #474747;
    }
    &-subtitle{
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      margin-top: 10px;
      color: #5c5c5c;
    }
  }

  .homePage-partners{
    text-align: center;
    padding-top: 1px;

    &-title{
      font-size :18px;
      color: $ca-blue;
    }

    &-list > li{
      margin: 10px 30px;
    }
  }

  .slider-nav { height: 750px; top: 0}
}