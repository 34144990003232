.imageContainer{
  position: relative;
  width: 100%;

  &--fullWidth{
    img{
      width: 100%;
      height: auto;
    }
  }

  &:hover {
    .imageContainer-textOnImage {
      display: block;
    }
    .imageContainer-banner {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.75);
    }
  }

  &-textOnImage{
    font-weight: bold;
    color: #fff;
    display: none;
    position: absolute;
    text-shadow: 0 0 10px #000;
  }

  &-banner{
    position: absolute;
    bottom: 0px;
    font-size: 120%;
    color: #fff;
    width: 100%;
    @include transition (background 0.3s linear 0s);
    background: none repeat scroll 0 0 #000;
    background-color: rgba(0,0,0,0.5);
  }
}

.imageContainer-leftNav{ @include lt_corner(1, 0, 0);
  border-radius: 5px 0 5px 0;
  box-shadow: 0 2px 8px 0 rgba(50, 50, 50, 0.75);
  padding: 5px 10px; background-color: #fff;
  @include display_on_hover('.imageContainer');
  > * {display: block}
}

.imageContainer-draft {
  display:block;
  font-weight:bold;
  background:#c00;
  color:#fff;
  z-index:1;
}

.imageContainer-hearting{
  color: #fff;
  position: absolute;
  top: 4px;
  right: 22px;
  font-size: 22px;
}

.imageContainer-stashing{
  position: absolute;
  top: 6px;
  right: 30px;
}

.imageContainer-wrapper{
  display: block;
  padding: 0 10px;
}

.imageContainer--brick{
  display: none;
  &.loaded{ display: block }
}

.imageContainer--col-4{
  width: 25%;
}

.imageContainer .tooltip-inner{
  min-width: 310px;
  background-color: rgba(247, 247, 247, 0.9)
}