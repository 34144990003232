.appPromo{
  padding-top: 10px;
  background-color: #0f232e;
}

.appPromo-header{
  font-size: 32px;
  color: $ca-blue;
  margin-bottom: 0px;
  letter-spacing: -0.04em;
}

.appPromo-desc{
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}

.appPromo-icon{
  width: 60px;
  margin-right: 12px;
}

.appPromo-btn{
  margin-top: 12px;
  margin-right: 21px;
}

.appPromo-close{
  position: absolute;
  right: 10px;
  top: 5px;
  color:#ffffff;
  font-size: 30px;
}