.cruiseIndexPage{
  &-title{}
  &-slider{margin-bottom: 49px}
  &-infoLink{ text-align: center; }
  &-infoLink-btn{
    font-size: 18px;
    font-weight: 700;
    padding: 16px 40px
  }
  &-heading{ font-size: 16px; line-height: 24px / 16px; text-align: center}
}
.cruiseIndexPage-cruisesList{
  > li{
    margin-bottom: 20px;
    padding: 30px 120px 30px 30px;
    position: relative;
  }

  > li:nth-child(2n-1){
    background-color: #ecebed;
  }

  dl {
    position: relative;
    padding: 5px 0 5px 45px;
    margin: 15px 0 0;
  }

  dt {
    position: absolute;
    left: 0;
    top: 0;
  }

  dd {
    float: left;
    padding: 3px 6px;
  }

  &-select {
    @include vertically-center;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 90px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.04em;
    text-align: center;
    text-transform: uppercase;
    background-color: $cruiseable-blue;
    color: #fff;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }

  &-year{
    width: 55px;
    vertical-align: top;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.04em;
  }

  &-month{
    font-size: 16px;
    letter-spacing: -0.06em;
    color: $ca-blue;
  }
}

.cruiseIndexPage-cruise{
  &-name{
    margin: 0px;
    margin-bottom: 5px;

    font-size: 14px;
    letter-spacing: -0.04;
    line-height: 20/14px;
    font-weight: 700;
    text-transform: capitalize;
  }
  &-route{
    margin: 0px;
    font-size: 12px;
    letter-spacing: -0.06em;
    line-height: 20/12px;
    color: #5c5c5c;
  }
}

.cruiseIndexPage-tableDates{
   &-year{
    width: 55px;
    vertical-align: top;
    font-weight: bold;
    font-size:14px;
    letter-spacing: -0.04em;
  }

  &-month{
    font-size: 16px;
    letter-spacing: -0.06em;
    color: $ca-blue;
  }
  &-month-list{
    margin-bottom: 0px
  }
}