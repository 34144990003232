.oobigo {
	&-logo { width: 280px; }
	&-LLSlogo { width: 200px; }

	&-header { padding-top: 20px; @include f(1.3em); }
	&-header img { margin-right: 30px }
	&-header p { padding-right: 20px }
	&-header .oobigo-logo { @include pos($pr); top: -15px; }

	&-desc { @include f(1.3em);}
	&-desc img { margin-right: 20px; }
	&-desc ol { margin-top: 1.5em}
	&-desc ol > li { padding: 0 0 1em 1em; }
	& .ca-topic-desc { font-size:1.2em; }
	& .ca-topic {
		margin-bottom: 2em;
		& .title1 { margin-top: 0.8em;}
		& .savings {color: #ED7966; line-height: 3.5px;}
		& .oobigo-btn {margin-top: 0.8em; font-size: 1em; font-weight: bold;}
	}

	&-pointer { display: inline-block; vertical-align: middle; @include in_circle($ratio: 3em); @include f(40px); }
	&-pointer span { display: block; padding-top: 1em;
		             @include f(0.5em, $ws, $c, $lh:1.2em); text-transform: uppercase; }
	&-pointer i { display: block; @include f(2.4em, $lh:0.5em); }

	&-promoSection { margin: 0 0 10px; padding: 10px 0;
					 @include f(1.6em, $wn, $c); @include sand_section(); }
	&-promoSection-call { text-transform: uppercase }
	&-promoSection-call > small { @include f(0.7em) }
	&-promoSection-card { @include f(0.6em, $a: $l) }
	&-promoSection-desc { @include f(0.8em, $ws, $c, $lh: 1.8em) }
	&-promoSection-link { margin-bottom: 30px; @include f(1.4em, $ws) }

	&-bliss.topic-bliss { margin: 0px }
}
