.cruiseSearch-results{

  margin-top: 20px;

  &-header{
    font-size:33px;
    color: #303030;
    margin-bottom: 33px;
  }

  &-how{
    font-weight: 600;
    font-size: 14px;
    color: $ca-blue;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  &-bookIt.btn--bookIt{
    margin-top: 0;
    padding: 3px 30px;
    width: auto;
    font-size: 14px;
    margin-bottom: 20px;
  }

}

.cruiseSearch-item{
  color: #303030;
  margin: 0 0 36px;
  border-bottom: 1px solid #8f8f8f;
  padding-bottom: 18px;


  &-imgHolder{}
  &-img{}
  &-body{ }
  &-title{
    margin-top: 0px;
    font-size: 21px;
    letter-spacing: -0.04em;
  }
  &-featured{
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: $ca-blue;
  }
  &-cruiseLine{
    margin-top: 0px;
    font-size: 16px;
  }
  &-desc{
    font-size: 18px;
  }
  &-meta{}
}

.cruiseSearch-meta{
  margin-top: 20px;

  > li{
    display: inline-block;
    width: 32%;
  }

  &-price{ font-style: italic; font-size: 16px; }
  &-price-value{ font-weight: bold }
  &-heart{ }
  &-details{ text-align: right;}
  &-details-link{text-transform: uppercase; color: #303030; white-space: nowrap}
}

.cruiseSearch-heart{

}