.shipPage{
  &-itineraryLink.btn.btn--primary{
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    &:hover{ color: #ffffff }
  }
}

.shipSailing{
  &-details{
  }

  &-images{
    margin: 15px 0px;
  }

  &-socialAsk{
    text-align: center;
    font-weight: 600;
  }

  &-cruiseName{
    color: $ca-blue;
    font: {
      size: 40px;
      weight: 300;
    }
    letter-spacing: -0.04em;
    line-height: 50px;
    text-transform: capitalize; 
  }
  &-header{

  }
  &-subheader{
    color: #303030;
    font:{size: 24px};
    letter-spacing: -0.02em;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
  }
  &-btn{
    margin-top: 3px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: -0.02em;
    text-transform: none;
  }
  &-btnHolder{
    margin: 60px 0px;
  }
  
  &-label{
    font-size: 24px;
    font-weight: bold;
  }

  &-name{
    font-size: 24px;
  }

  &-name.shipSailing-rating{
    font-size: 8px;
  }

  &-sailingInfo{
    margin-bottom: 30px;
  }

  &-shipInfo{
    margin-bottom: 16px;
    > li{
      margin-bottom: 6px;
    }
  }

  &-datesBtn,
  &-reserveBtn.btn,
  &-requestBtn.btn{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
  }

  &-priceTitle{
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }

  &-priceTag{
    margin-bottom: 16px;
  }
  &-priceTagList{
    margin-bottom: 0px;
  }
  &-priceDesc{
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: -0.04em;
  }
  
}

.shipPackage{
  &-title{ text-transform: capitalize}

  &-details{ }
  &-details > li{
    width: 25%;
    min-height: 80px;
    border-right: 1px solid #CCCCCC;
    &:last-child{ border-right: 0px; }
  }

  &-price{text-align: center; margin-bottom: 20px}
  &-price-label{color: #CCCCCC; display: block}
  &-price-value{font-size: 40px}

}

.details{
  font-size: 18px;
  margin-bottom: 16px;

  > li{
    margin-bottom: 8px;
  }
  &-term{
    color: #303030;
    font-weight: bold;
    letter-spacing: -0.02em;
  }
  &-description{
    color: #5c5c5c;
    line-height: 20px;
  }
}