.moreLoader-btnHolder{
  [masonry] &{
    position: absolute;
    bottom: 0px;
  }
}

.moreLoader-btn{
  @include button-variant(#4A4A4A, #F2EDE1, #F2EDE1);
}
