.planCruise {
    width: 920px;
    margin: auto;
    font-size: 18px;
}

.planCruise-head {
    margin-bottom: 20px;
    text-align: center;
}

.planCruise-required {
    &:after {
        content: '*';
        color: #f72a4c;
    }
}

.planCruise-message {
    margin: 50px 0;
    text-align: center;
    font-size: 30px;
}

.planCruise-calendar {
    padding: 0 5px;
    margin-bottom: 5px;
    background: $ca-blue;

    .cruiseSearch-filters-date-month-label {
        width: 43px;
        font-size: 12px;
    }

    .col-sm-4 {
        margin-bottom: 15px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

.planCruise-section {
    margin-bottom: 40px;
}

.planCruise-section-head {
    float: left;
    width: 220px;

    h3 {
        margin-top: 0;
        font-size: 25px;
    }
}

.planCruise-section-column {
    float: right;
    width: 280px;

    &:first-child {
        float: left;
    }
}

.planCruise-map {
    position: relative;
    margin-bottom: 5px;
    height: 397px;
    font-size: 14px;
    background: url(/static/img/common/map/destinations_map.png) no-repeat left top;
    background-size: 100%;

    .cruiseFilter-destination-region-ico {
        height: 32px;
        transform: scale(0.8);
    }
}

.planCruise-multilist {
    padding: 15px;
    margin: 20px 0 0;
    border: 1px solid $ca-blue;
    font-size: 13px;
    line-height: 17px;
    text-align: center;

    label {
        display: block;
        position: relative;
        margin: -3px 0;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;

        span {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: #fff;
        }
    }

    input:checked + span {
        color: $ca-blue;
    }

    li {
        display: block;
        padding: 3px 0;
        border-top: 1px solid $ca-grey;
    }

    li:first-child {
        border: 0;
    }
}

.planCruise-section-content {
    float: right;
    width: 670px;

    .planCruise-section-head {
        float: none;
        width: auto;
    }
}

.planCruise-interests {
    padding: 0;
    margin: 0 0 0 -5px;
    text-align: center;
    font-size: 12px;

    li {
        display: block;
        float: left;
        width: 75px;
    }

    label {
        padding-top: 65px;
        display: block;
        position: relative;
        cursor: pointer;
    }

    input {
        position: absolute;
        left: 0;
        top: 30px;
        right: 0;
        margin: auto;
    }

    input:checked + img {
        top: -3px;
        border: 3px solid orange;
    }

    img {
        box-sizing: content-box;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
        display: block;
        width: 60px;
        height: 60px;
    }

    span {
        display: block;
        white-space: nowrap;
    }
}

.planCruise-submit {
    margin-bottom: 45px;
    text-align: center;
    font-size: 16px;

    button {
        margin: 0 auto 15px;
        width: 390px;
    }

    & + p {
        text-align: center;
    }
}

.planCruise .form-control.required::-webkit-input-placeholder:after {
  content: "*";
  color: #f72a4c; }

.planCruise .form-control.required::-moz-placeholder:after {
  content: "*";
  color: #f72a4c; }

.planCruise .form-control.required:-moz-placeholder:after {
  content: "*";
  color: #f72a4c; }

.planCruise .form-control.required:-ms-input-placeholder:after {
  content: "*";
  color: #f72a4c; }

.planCruise-info {
    float: left;
    width: 330px;
}

.planCruise-input-city {
    width: 220px;
    float: left;
    margin: 0 15px;
}

.planCruise-input-state {
    width: 95px;
    float: left;
}

.planCruise-passengers {
    width: 300px;
    float: right;
    padding-top: 6px;

    label {
        margin-bottom: 15px;
        display: block;
        font-size: 14px;

        span {
            color: #f72a4c;
        }
    }
}

.planCriuse-increment {
    float: left;
    width: 115px;
}

.planCruise-numbers {
    .form-control {
        width: 80px;
    }
}

.planCruise-interestsList {
    border: 1px solid $ca-blue;
    padding: 19px;
    margin: 0;

    label {
        display: block;
        position: relative;
        font-weight: normal;
        font-size: 14px;
        line-height: 25px;
        overflow: hidden;
        cursor: pointer;
    }

    li {
        display: block;
        float: left;
        width: 200px;
        margin: 0 5px;
    }

    input {
        position: absolute;
        right: 120%;
    }

    input:checked + span {
        background: #A8DBF9;
    }

    span {
        display: block;
        padding: 0 10px;
    }
}

.planCruise-section-interests {
    .planCruise-interests {
        margin-bottom: 15px;
    }

    .planCruise-section-head {
        padding-top: 102px;
    }
}