.btn{
  @extend %btn;
//  @include button-size(9px, 27px, 14px, 15px, 6px);
  border-radius: 6px;
  &:focus, &:focus:active{
    outline: 0px;
  }
}
.btn--primary{
  @include button-variant(#ffffff, $cruiseable-blue, $cruiseable-blue);
}
.btn--subscribe{
  @include button-variant(#ffffff, #ed7966, #ed7966);
}
.btn-large{
  @extend %btn;
  @include button-size(20px, 40px, 25px, 25px, 6px);
}

.btn--secondary{
  @include button-variant(#ffffff, #ED7966, #ED7966);
}

.btn--grey{
  @include button-variant(#ffffff, #474747, #474747);
}

.btn--bookIt{
  @extend .btn--secondary;
  background-color: #ED7966;
  border-color: #ED7966;
}
.btn--whyBook{
  @extend .btn--primary;
}

a.btn.btn--itineraryLink{
  @extend .btn--grey;
  @include button-variant(#ffffff, #A3A3A3, #A3A3A3);
  color: #fff;
  font-size: 25px !important;
  &:visited, &:hover, &:link{
    color: #fff;
    text-decoration: none;
  }
}

.btn--bookIt, .btn--whyBook, .btn--itineraryLink{
  margin-top: 20px;
  width: 100%;
  font-weight: 600;
}

.btn-large.btn--freeGift{
  @include button-size(16px, 32px, 20px, 20px, 5px);
}


.btn--dropdown{
  @include button-size(5px, 12px, 14px, 15px, 6px);
  @include button-variant(#757475, #E0E0E0, #E0E0E0);
  text-align: left;
  text-transform: none;
}
.btn-xs{
  @include button-size(1px, 5px, 12px, 1.5, 3px);
}

.expandSign{
  display: block;
  @include center-block;
  width: 50px;
  padding-bottom: 30px;
  background: transparent url(../img/common/more_arrow.png) no-repeat center bottom;
  font-size: 20px;
  text-align: center;
  color: $cruiseable-blue;
}