.tagGallery-nav-search{
  margin: 20px auto;

  &-sections{
    margin-top: 4px;
  }
}

.galleria-moreChoices{ @include pos ($pr); margin-bottom: 20px;
	&-opener{
		@include b (1px, $grey); padding: 5px;
		@include f (18px, $ws, $c); @include c ($ca-black, $ca-yellow);
		&:hover{ text-decoration: none; }
	}
	&-close{ @include pos ($pa, $i: 1021); right: 20px; }
	&-tags{ @include pos ($pa, $i: 1020);
		padding: 20px; @include size(100% auto);
		@include c ($grey, $ca-bg-grey);}
	&-column{  }
	&-head { @include c ($ca-black); @include f (24px, $ws, $l); }
	&-tag { @include f (16px); }
	&-counter { @include c ($grey); }
}


.galleria-listCreator{ border-top: 1px $ca-black solid;
	&-title { @include f (18px, $ws);
		small {@include f (18px, $wn)}
	}
	&-step {@include f ( 14px, $ws); float: $r;}
	&-dropAreaWrapper { @include pos($pr);
		 				margin-bottom: 10px; @include b (); padding: 20px;
						width: 100%; min-height: 120px; display: table}
	&-dropArea { display: table-cell; vertical-align: middle}
	&-dropAreaPlaceholder { @include f (24px); @include c ($grey);
		img {margin-right: 10px; @include opacity(0.5);}
	}
	&-dropAreaUploadLayer { @include pos($pa); top: 0px; left: 0px; padding: 0px 130px;
							@include size(100%); @include c(#fff, rgba(0,0,0,0.4))
	}
	&-dropAreaUploadLayer-content { @include vertical-align }
	&-browse { @include f(18px) }
	&-btn { float: $r; padding: 1.5% 3.2%; @include f(27px) }
	&-removeBtn { @include lt_corner(); }
	&-imagesList {}
	&-imageWrapper { @include pos($pr); margin-bottom: 10px; }
	&-image { height: 160px; }
}

.galleria-listCreator-form{ padding: 0px 20px 20px;
	&-headline { margin-bottom: 20px;
		label { display: none }
		.form-control { margin-bottom: 30px; @include f (24px) }
	}
	&-content {
		label {@include f (18px, $wn)}
	}
	&-images {}
	&-tags label { display: none}
	&-publishRow {margin: 20px 0px 10px}
	&-publish { @include b(); padding: 15px; min-height: 360px;
	    @include c($ca-blue, $ca-bg-grey); @include f(20px, $a: $c);

		&:hover {cursor: pointer; @include c($b: darken($ca-bg-grey, 10%))}
		img { margin: 10px; }
	}
	&-publishDesc { @include f(18px, $a: $c); @include c($grey) }

	.mce-wyswig-container > textarea {height:200px;}
	.selectMedia-table {width: 100%}
	.selectMedia-option { height: 120px;}
	.selectImage {margin: 10px 0px}
	.galleria-listCreator-title { @include f(24px, $a: $c); margin-bottom: 20px;}
	.selectMedia { border: 0px; @include c($b: #fff)}
	.selectMedia-index { @include size(28px);
		@include f(16px, $wb, $lh: 28px); @include c($ca-blue, $ca-bg-grey)}
}

.listCreator-editBar{position:fixed; bottom: 0; padding: 10px; width: 100%;
	@include c(#fff, $ca-black); @include f(18px, $wb, $a: $c); z-index: 998;
}

.listCreator-share{ padding: 20px; @include f ($a: $c);
	h1 { @include f (30px, $ws); @include c($ca-blue); }
	img {margin: 0px 10px}
}

.draggable{
	[moderator-ban-image], [stash-this], [heart-this] { z-index: 30; }
}