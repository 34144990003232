@mixin circlify($size, $fontSize, $lineHeight:$size){
  display: block;
  border-radius: 50%;
  @include size($size);
  font-size: $fontSize;
  line-height: $lineHeight;
  text-align: center;
}

.ui-increment{
  &-count{
    @include circlify(60px, 40px);
    background-color: #56a6db;
    color: #ffffff;
    margin: 0 10px;
  }
  &-count--adult{}
  &-count--kids{
    background-color: #baddf5;
  }

  &-input{
    margin-right: 10px;
    @include circlify(25px, 25px);
    border: 1px solid #000000;
    cursor: pointer;
  }
  &-input--more{
    line-height: 22px;
  }
  &-input--less{
    line-height: 20px;
  }

  &-label{
    text-align: center;
    padding-top: 6px;
    text-transform: uppercase;
  }
}