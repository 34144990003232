$formBorder: 1px solid #d7d7d7;
$anchorColor: #1ccfef;
$adminForm-paddingHor: 4px;
$adminForm-paddingVer: 12px;

$adminForm-row: 20px;

$form-bgColor: #fcfcfc;

%formElement{
  background-color: $form-bgColor;
  border: $formBorder;
  border-radius: 6px;
  box-shadow: none;
}

%formStyle{

  padding: $adminForm-paddingHor $adminForm-paddingVer;
  margin: $adminForm-row * 1.5 0px;

}

.uploadButton-wrapper{
  display: none;
}

.form{
  .form-group, .form-custom-element{
    margin-bottom: $adminForm-row * 1.5;
  }
  .control-label{
    @extend .sr-only;
    @extend .sr-only-focusable;
  }
  .control-label.control-label--visible{
    @extend .control-label;
  }
  .control-label--lg{
    font-size: 30px;
    font-weight: normal;
  }

  input.form-control{
    @extend %formElement;
    height: $adminForm-row * 2;
  }
  .select2-choices{
    padding: ($padding-base-vertical - 3px) ($padding-base-horizontal - 3px);
    font-size: $font-size-base;
    color: $input-color;
    @extend %formElement;
    background-image: none;

  }
  &-body{
    a, .btn-link {
//      color: $anchorColor;
    }
  }
  &-legend{
    margin-bottom: $adminForm-row * 1.5 ;
    font-weight: normal;
  }

  .input--large{
    font-size: 18px;
  }

}

.selectMedia{
  @extend %formElement;

  &--imageOver{
    border-color: red;
  }

  &-table{
    margin: $adminForm-row (-$adminForm-paddingVer);
  }

  &-option{
    height: $adminForm-row * 13;
    padding: $adminForm-paddingHor $adminForm-paddingVer*3;
    vertical-align: middle;
    text-align: center;
    border-right: $formBorder;
    &:last-child{
      border-right: 0px;
    }
    > a{
      cursor: pointer;
    }
  }

  &-option-image {
    clear: both;
    margin-bottom: $adminForm-row;
    text-align: center;
  }

  &-row{
    position: relative;
  }

  &-mediaCell{
    position: relative;
  }

  &-index{
    color: white;
    background-color: black;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 18px;
    @include size(18px 18px);
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &-remove{
    position: absolute;
    top: 6px;
    right: 7px;
    cursor: pointer;
    display: none;
    .selectMedia-row:hover &{ display: block; }
    .selectMedia-mediaCell:hover &{ display: block; }
  }

  &-sortable{
    cursor: move;
  }

  &-legend{
    color: #919191;
  }

}
