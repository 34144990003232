.featuredImages{
  margin-bottom: 40px;
  @include box-shadow(0px 2px 8px 0px rgba(50, 50, 50, 0.75));
  background-color: #ffffff;
  border-radius: 6px;
  text-align: center;
  position: relative;

  .imageContainer > img { @include border-top-radius(6px) }


  &-body{
    padding: 0px 10px;

    hr{
      margin: 5px 0px 0px;
    }
  }

  &-title{
    margin-top: 15px;
//    padding-bottom: 10px;
    font-size: 22px;
    color: #404040;
//    border-bottom: 1px dashed $light-gray;
  }

  &-description{
    @extend .list-unstyled;
    font-size: 14px;

    &-label{ color: #acacac; }
    &-label--profile{ color: #3d3c3c; letter-spacing: -0.04em; line-height: 1.1; font-weight: 600; padding: 6px 0px; }
    &-text{ color: #5e5e5e; }
    &-ratingContainer{
      position: absolute;
      bottom: 5px;
      width: 100%;
    }
  }

  &--firstInRow{
    clear: left;
  }

  &--withMeta{
    padding-bottom: 10px;
  }

  &--withoutSpacer{
    margin-bottom: 0px;
  }

  &--asTableCell{
    vertical-align: top;
    padding-bottom: 30px; 
  }

  &-image--fitToBorders{
    @include border-top-radius(6px)
  }

  &-hearting{
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 12px;
    font-size: 22px;
    &:hover{
      color: #ffffff;

    }
  }

  @include ngFadeInAnimation();
}

.featuredImages-tags{
  padding: 10px;
  @include border-bottom-radius(6px);
  margin: 0 -10px;
  .featuredImages:hover &{
//    a{ color: $ca-blue; }
//    background-color: $ca-grey;
  }
}

.featuredImages.featuredImages--relatedArticle{
  .contentListing-caption{
    height: 83px;
    font-size: 21px;
    line-height: 1.3;
  }
  .imageContainer .croppedImage{
    height: 207px;
  }
}
