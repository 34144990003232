.spinningLoader{
  text-align: center;

  &-spin{
    display: inline-block;
  }
}

.spinningLoader--lg{
  .spinningLoader{
    &-spin{
      font-size: 80px;
    }
  }
}

.spinningLoader--inline{
  .spinningLoader{
    &-spin{
      display: inline-block;
    }
  }
}