@mixin topArrow{
  &:after, &:before {
    bottom: 100%;
    left: 75%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #ccc;
    border-width: 11px;
    margin-left: -11px;
  }
}

.fluidBar{
  padding: 0px 20px;
  border-bottom: solid $light-gray 1px;
  text-align: right;

  &-title{
    margin: 10px 0px;
    text-align: center;
    font-weight: normal;
  }

  > a{
    margin-left: 20px;
    color: #4B4B4B;
    line-height: 40px;
  }
}



.sectionSelector{
  margin-top: 10px;
  width: 100%;

  &-legend{
    text-align: center;
  }

  &-menu{
    text-align: left;
    width: 250px;
    left: -23%;
    top: 39px;

    @include topArrow;
  }

  &-section{
    line-height: 50px;
    border-top: solid #e5e5e5 1px;
    &:last-child{
      border-bottom: solid #e5e5e5 1px;
    }
    img{
      margin-right: 10px;
    }
  }

  .dropdown-menu > li > a{
    padding: 3px 10px;
    font-size: 20px;
    color: #363636;

    > .sectionSelector-count{
      font-size: 16px;
      color: #363636
    }
  }

}

