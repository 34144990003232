.objHolder{
  $footerSize: 20px;
  $padding-bottom: 10px;

  @include box-shadow(0px 2px 8px 0px rgba(50, 50, 50, 0.75));
  vertical-align: top;
  display: table-cell;
  background-color: #ffffff;
  border-radius: 6px;
  position: relative;
  padding-bottom: $padding-bottom;

  &--withFooter{
    padding-bottom: $footerSize + $padding-bottom;
  }

  &-footer{
    position: absolute;
    bottom: $padding-bottom;
    left: 0px;
    width: 100%;
    height: $footerSize;
  }
}