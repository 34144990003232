.angular-google-map-container { height: 700px; width: 100%}
.map-label {
  }

.cruiseFilter-destination{
  background-color: #4d4d4d;

  &-container{
    width: 1024px;
  }

  &-header{
    margin: 0px;
    padding: 10px 0px 10px 4px;
    background-color: #4d4d4d;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.02;
    color: #ffffff;
  }

  &-mapImg{
    background: url("/static/img/common/map/destinations_map.png");
    width: 1024px;
    height: 607px;
    position: relative;
  }

  &-region{
    display: block;
    position: absolute;
    text-align: center;
    cursor: pointer;

    &-label{
      white-space: nowrap;
    }
    &:hover > &-label{ text-decoration: underline; }

    &-ico{
      margin: -5px auto 0px;
      display: block;
      @include size(40px);
      background: url("/static/img/common/map/destinations_map_icon.png") no-repeat 50% 50%;
    }
    &.active > &-ico{ background-image: url("/static/img/common/map/destinations_map_icon_coral_red.png")}

  }

  &-search{
    background-color: #4d4d4d;
    padding: 10px;
  }

  &-closeout{
    position: absolute;
    top: 10px;
    right: 10px;
    @include size(26px);
    background: url("/static/img/common/closeout.png") no-repeat 50% 50%;
  }

  &-done{
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 0px;
    padding: 5px 50px;
    background-color: rgba(0,0,0,0.7);
    color: #ffffff;
    font-size: 30px; }
  &-done:hover{ color: #ffffff; text-decoration: none; background-color: rgba(0,0,0,1); }

  &-specificDestinationForm{
    position: relative;
    margin: 4px 0px 0px;
  }
  &-specificDestinationInput{
    border-radius: 16px;
    height: 26px;
  }
  &-specificDestinationInput-searchIcon{
    position: absolute;
    top: 6px;
    right: 7px;
  }
}

.cruiseFilter-destination-destinationList{
  padding-top: 3px;
}

.cruiseFilter-destination-region-label{
  .cruiseFilter-destination-region-68 > &{
    position: relative;
    bottom: -25px;
    right: 65px;
  }
  .cruiseFilter-destination-region-59 > &{
    position: relative;
    bottom: -25px;
    right: 65px;
  }
}