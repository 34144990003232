.stashboard{

  &-headerBlock{
    margin-top: 20px;
  }

  &-subheaderBlock{
    text-align: center;
    font-size: 24px;
    > span{
      color: $ca-red;
    }
    > span > i{
      width: 30px;
    }
  }

  &-subheader{
    color: #ffffff;
    background-color: $ca-blue;
    padding: 4px 8px;
    margin-bottom: 20px;
  }

  &-modal{
    text-align: center;
  }
}


.btn.stashboard-removeBtn{
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  position: absolute;
  top: 6px;
  left: 6px;
  display: none;

  .stashboard-item:hover &{
    display: block;
  }
}

