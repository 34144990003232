$legendTable-width: 868px;
$legendCell-width: 160px;
$columnSpace-width: 24px;

.compareCruises{}

.compareCruises-heading{
  text-align: center;
  margin-bottom: 36px;
}

.compareCruises-calendar{
  display: block;
  background-color: $ca-blue;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.compareCruises-table{
  width: $legendTable-width;
  text-align: center;
}
.compareCruises-table > tr{
  border:0;
}

.compareCruises-legend {
  text-align: left;
  width: $legendCell-width;
  padding: 0 $columnSpace-width/2
}
@each $column in 1,2,3{
  .compareCruises-table-col-#{$column}{
    .compareCruises-dataCell{
      width: ($legendTable-width - $legendCell-width) / $column;
    }
  }
}
table.compareCruises-table > tbody > tr > td{
  border: 0;
  padding: 0;
}
table.compareCruises-table > tbody > tr > td.compareCruises-dataCell{
  border: solid white $columnSpace-width/2;
  background-color: #f8f8f8;
  border-top: 0;
  border-bottom: 0;

  &.inactive{ opacity: 0.3 }
}
.compareCruises-title{
  text-transform: capitalize;
}

.compareCruises-ship {
  font-size: 16px;
  margin-bottom: 6px;
  display: inline-block;
}

.compareCruises-dataCellInner{
  border: 1px solid $ca-grey;
  border-top: 0;
  border-bottom: 0;
  padding: 0 15px;
}
.compareCruises-data{
  border-top: 1px solid $ca-grey;
  padding: 10px 0;
}

.compareCruises-table > tbody > tr:last-of-type > td > .compareCruises-dataCellInner{
  border-bottom: 1px solid $ca-grey
}


.compareCruises-btnRemoveHolder{
  text-align: right;
  padding: 10px 0 10px;
}
.compareCruises-btnRemove.btn{
  @include button-variant(#000, $ca-grey, $ca-grey);
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.compareBar{
  z-index: 100;
  position: fixed;
  bottom: 0;
  padding: 10px 10px 0px;
  width: 100%;
  background-color: $ca-grey;

  &-list{
      margin-bottom: 0;
  }

  &-cruise{
    margin: 0 10px 10px;
    padding: 5px;
    background-color: transparentize($ca-blue, 0.85);
    color: #ffffff;
    cursor: pointer;
  }

  &-cruise.active{
    background-color: $ca-blue;
  }

  &-cruiseDetails{
    margin-right: 17px;
  }


}
