.routeMap{
  position: relative;
  width: 100%;
  height: 600px;


  &-loader{
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    z-index: 1000
  }

  &-map{
    position: absolute;
    height: 565px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #d6d0c5;
  }
  &-label{
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: center;
    > span{
      font-size: 12px;
      position: absolute;
      right: 0px;
    }
  }

  &-modal{
    width: 50%;
    z-index: 100;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }

  //Do not display labels on igemoe map
  .rollover{ display: none !important; }
  .rollover_tx{ display: none !important; }

  &-wrapper{
    position: relative;
  }

  &-modal{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 110;
    width: 50%
  }

  &-description{

  }

}