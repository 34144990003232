$credit-color: #3d3c3c;
$font-size-small: 12px;
$font-size: 14px;
$block-margin: 40px;

$image-margin: $block-margin / 2;

.image{
  &-wrapper{ margin-bottom: 14px;}
  &-wrapper--toLeft{ float: left; margin-right: 10px}
  &-wrapper--toRight{ float: right; margin-left: 10px}
  &-credits{
    font-size: $font-size-small; text-align: right; display: block;
  }
  &-caption{ font:{ weight: 600; size: $font-size } display: block;}
}

.mainImage{
  margin-top: $block-margin;
  color: $credit-color;

  > img{
    //    margin-bottom: $image-margin;
  }

  &-credits{
    @extend %openSans;
    //    margin-top: -$image-margin;
    font-size: $font-size-small;
    text-align: right;
  }
  &-caption{
    @extend %openSans--semiBold;
    font-size: $font-size;
  }
}