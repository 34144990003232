.blog{}

.blog-headerSection{
  margin-bottom: 0px;
}

.blog-header{
  margin-top: 20px;
  margin-bottom: 10px;
}
.blog-entry{
  padding: 20px;
  background-color: white;
}

.blog-entry-image{
  text-align: center;
}

.blog-date{
  float: right;
  margin-top: 10px;
  text-align: right;
}
.blog-snippet{
  // margin-top: 20px;
  clear: right;
}
.blog-moreWrapper{
  text-align: right;
}
.blog-more{
  font-weight: bold;
  color: #0cb2eb;
}

.blog-categoryNav{
  text-align: center;
  margin-bottom: -10px;
}

.blog-categoryNav > li{
  &:after{
    content: "|";
    padding-left: 13px;
  }
  &:last-child:after{
    content: "";
    padding-left: 0;
  }
}

.blog-categoryNav > li > a{
  color: #4E4E4E;
  font-size: 16px;

  &.active{
    color: $ca-blue;
  }
}

.blog-description{
  text-align: center;
  &-subheader{
    margin-top: 0;
  }
  &-text{
    color: #858585;
  }
}