%iconStyling {
  display: inline-block;
  @include size(30px 20px);
  content: "";
  background: no-repeat right 50%;
  vertical-align: text-top;
  margin-right: 6px;
}

@mixin doubleIconVibe($name) {
  & > span:first-child {
    float: left;
    &:before {
      @extend %iconStyling;
      background-image: url("/static/img/bliss/" + $name + "_l.png");
      background-position: left 50%;
      width:16px;
    }
  }

  & > span:last-child {
    float: right;
    &:after {
      @extend %iconStyling;
      background-image: url("/static/img/bliss/" + $name + "_r.png");
    }
  }
}

.vibesFilters {
  &-header {
    margin-bottom: 15px; 
    padding: 5px 0;
    background-color: $ca-blue;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
  }

  &-header--link { }

  &-header-text {
    padding: 0 40px;
    display: inline-block;
  }

  &-reset.btn{
    float: right;
    text-transform: lowercase;
    color: #303030;
    &:hover{ color: $ca-blue}
    font-size: 14px;
  }
}

.vibesFilters-filter-header {
  text-align: center;
  color: #303030;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.04em;

  &:before {
  }

  &--bliss_party{
    @include doubleIconVibe('bliss_party')
  }

  &--bliss_family_friendly{
    @include doubleIconVibe('bliss_family_friendly')
  }

  @each $icon in bliss_staterooms, bliss_dining,
  bliss_romantic, bliss_active, bliss_entertainment,
  bliss_adventure_nature, bliss_cultural, bliss_spa_fitness  {
    &--#{$icon}:before {
      @extend %iconStyling;
      background-image: url('/static/img/bliss/#{$icon}.png');
    }
  }
}

.vibesFilters-filter-header--bliss_staterooms{ @include pos($pr); margin-top: 40px; }

.vibesFilters-filter-legend{ @include pos($pa); left: 0px; top: -35px; padding: 2px 6px; 
  width: 100%; @include f(0.8em, $ws, $l); @include c(#fff, $ca-black);
  & > span:last-child { float: $r; display: inline-block; }
}

.vibesFilters-filter-slider {
  margin: 11px 0 20px 0;

  span.rz-bar{
    background-color: #EDEDED;
    height: 1px;
  }

  .rz-bubble{ display: none; }
  span.rz-pointer{ background-color: transparent; }
  span.rz-pointer:after{
    top: 6px;
    left: 12px;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 3px solid #000000;
    border-radius: 50%;
  }
  span.rz-pointer.active:after, span.rz-pointer:hover:after{ background-color: $ca-blue; }

}