.review{
  $review-baseColor: #575757;
  //line-height: 28px -> 42px -> 56px -> 70px
  $-subtitle-fontSize: 30px;
  $-subtitle-baseline: 1.16;
  $-subtitle-letterSpacing: 0em;

  &-subtitle{
    @extend %baseSubheader;

    font-weight: 600;
    font-size: $-subtitle-fontSize;
    line-height: $-subtitle-baseline;
    letter-spacing: $-subtitle-letterSpacing;
  }

  &-body{
    @extend %baseText;
    font-size: 17px;
    line-height: 1.65;
    color: $review-baseColor;

    h2{
      @extend .review-subtitle
    }

    h3 {
      @extend %baseH3;
      font-size: 24px;
      color: $review-baseColor;
      line-height: 1.1;
      letter-spacing: 0em;
      margin: 20px 0 10px;
    }

    img {
      @extend %baseImage;
    }

    > p {
      @extend %baseParagraph;
      margin-bottom: 10px;
    }

    a {
      @extend %baseAnchor;
    }
  }

  &--prime.review-body > p:first-of-type:first-letter,
  & .bigCapped:first-letter{
      @extend %bigCap;
      margin-top: 10px;
      font-size: 22px;
      padding: 13px 14px;
  }

  @include image-caption

}