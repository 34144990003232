@mixin ca-link() {
	@include transition(color 0.2s $ease-out-cubic);
	&:hover { color: $ca-blue; text-decoration: none; }
}

.ca-header { @include f(42px, $ws); margin-bottom: 0.66em}
.ca-subheader { @include f(25px, $wn); margin-bottom: 30px; }
.ca-header + .ca-subheader { margin-top: -0.66em; }
.ca-subheader + .ca-header { margin-top: -0.3em; }
.ca-subheader {margin-top: 0px}

.ca-headerSec {}
.ca-subheaderSec {}

.ca-article {
	&-title { @include f(2em, $wn); }
	&-title > a { @include ca-link; @include f($c: $ca-black);  }
	&-category { margin-bottom: $ln33; @include f(1.2em, $ws); color: $ca-red; text-transform: uppercase }
	&-meta { margin-bottom: 1em; color: $ca-txt-grey }
	&-date {}
	&-snippet { @include f(1.2em); color: $ca-text; }
	&-thumb { @include cropped_thumb (inherit 20em); @include rounded; @include b($c: $ca-sand); }
	&-thumb > img { @include rounded }
	&-btnWrapper { width: 80%; margin: 0px auto  }
	&-btn { @include f(1.3em, $lh: 1em); margin-bottom: $p-lh/2}
	&-btn > span { @include f(0.8em) }
	&-content { padding: 10px 10px $p-lh/4; }
}

a.ca-article-link { @include ca-link }


.ca-article.ca-article--sm { margin-bottom: 40px; @include c($b: #fff); @include multiline-ellipsis(4.2em, 1.4em, 7em);}
.ca-article.ca-article--sm .ca-article{
	&-thumb { height: 190px; }
	&-title {@include f(1.3em, $ws, $lh: 1.4em) }
	&-title > a { @include ca-link; @include f($c: $ca-black);  }
	&-date { @include f(0.9em, $ws) }
}

.ca-article.ca-article--md { margin-bottom: 40px;}
.ca-article.ca-article--md .ca-article{
	&-thumb { }
	&-category { @include f(1em) }
	&-title { margin-bottom: 15px; height: 2.45em; @include f(1.8em); line-height: 1.2em; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;  }
	&-title > a { @include ca-link; @include f($c: $ca-black); }
	&-meta { margin-bottom: 1.5em; }
	&-content { border: $default-border; }
}

.ca-userMeta { @include ca-link;
	&-avatar { @include circled;; @include cropped_thumb(3em);
			   display: inline-block; margin-right: 4px; vertical-align: middle; 
		.ca-userMeta-avatarPortrait {
			width: 100%;
			height: auto;
		}
	}
	&-name { @include f(1.2em, $wn); color: $ca-blue; }
}

.ca-deal { @include b($r:0); padding: 10px 15px; @include sand_section; text-align: $c;
	&-subtitle { margin-top: 0px; @include f(1.2em, $a: $c, $w: $ws);
				 @include c($ca-txt-grey); text-transform: uppercase; }
	&-thumb { @include pos($pr); }
	&-thumb > img { @include rounded; max-width: 100%; }
	&-ribbon { @include pos($pa);  top: 25px; left: -4px; padding: 8px 3px; @include c(#fff, $ca-red); }
	&-ribbon:after { @include pos($pa); top: 0px; right: -17px;
		   			 content: ""; @include triangle(35px, $ca-red, right); }
	&-title { margin: 10px 0px 5px; @include f(1.5em, $a: $c, $w: $wb);  }
	&-desc { @include f(1.2em) }
	&-meta {}
	&-cabin { @include f(0.9em) }
	&-price { @include f(1.5em, $w: $ws); @include c($ca-red) }
	&-link { padding: 5px 60px; @include f(1.5em, $w: $ws); }
	&-additional { margin: 10px 5px 5px; }
}

.ca-backToTop { @include pos($pf, $r: 20px, $b: 20px); @include size(45px);
				@include b($c: $light-gray); @include f(1.4em, $a: $c, $lh: 42px);
				@include c($ca-black, $ca-sand); z-index: 999; }

ol.ca-ol { @include ol_enumerate; padding-left: 1.5em; }
ol.ca-ol > li:before { left: -1.5em; font-size: 18px; @include in_circle(1.5em); }

.ca-red { color: $ca-red; }
.ca-blue { color: $ca-blue; }

.ca-itemMeta { margin-top: 10px; border-top: 1px solid $grey; border-bottom: 1px solid $grey; padding: 2px 14px;
			   overflow: hidden; font-size: 16px; }
.ca-itemMeta-date { float: $l }
.ca-itemMeta-categories { float: $l; margin: 0; padding: 0;
	&:before { content: '/'; float: $l; margin: 0 0.4em; display: block; }
	> li { float: left; display: block }
	> li:before { content: ', '; display: inline }
	> li:first-child:before { display: none }
	> li > a { text-decoration: none!important }
}

.ca-topic {
	& { @include pos($pr); margin-bottom: 3em; }
	&-title { @include pos($pa, 0); padding: 0.2em 0; width: 100%;
		      @include f(1.7em, $ws, $c, $c: #fff); @include c($b: rgba(0,0,0,0.3)) }
  	&-title--center { @include pos($pa, 40%); }
	&-img { width: 100% }
	&-desc { @include f(1.3em, $ws, $c) }
}

.ca-sideNav {
	& { padding-left: 0px; list-style: none; text-transform: uppercase; @include f(16px, $c: $ca-text); }
	&--sub { padding-left: 20px; list-style: none; }
	&-wrapper { background-color: #fff }
	&-legend { margin-bottom: 0; border-bottom: 0px; text-transform: uppercase; @include f(16px, $ws); }
	&-header { margin-top: 0px; @include f(1.3em, $wb); }
	&-text { @include f(1.2em); }
	li { padding-left: 4px; }
	li:hover ul { background-color: #fff }
	li.active  { background-color: $ca-blue }
	a { display: block; @include f($c: $ca-text) }
	a:hover { @include f($c: $ca-text); text-decoration: underline; }
}
.dropdown {
	> cruise-line-list { display: none; }
	&.open > cruise-line-list { display: block; }
}


.ca-postListing {
	&-image {}
	&-header {}
	&-title { @include f(2em); margin-bottom: $ln50 }
	&-title > a { @include f($c: $ca-text); @include ca-link() }
	&-author { margin-bottom: $ln }
	&-author .userAvatar { margin-top: 0px}
	&-date {}
	&-share {}
	&-share .socialActions { margin: $ln 0 $ln50; }
	&-snippet { margin-bottom: 20px; @include f(1.2em, $lh: 1.4, $a: $l) }
	&-moreWrapper { @include f(1.2em, $a: $c); text-transform: uppercase }
	&-more {}

}

.ca-topic {
	& { @include pos($pr); margin-bottom: 3em; }
	&-title { @include pos($pa, 0); padding: 0.2em 0; width: 100%;
		      @include f(1.7em, $ws, $c, $c: #fff); @include c($b: rgba(0,0,0,0.3)) }
  	&-title--center { @include pos($pa, 40%); }
	&-img { width: 100% }
	&-desc { @include f(1.3em, $ws, $c) }

}