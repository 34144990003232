.imagePopup{
    border-radius: 4px;
    border: 1px solid $ca-grey;
    padding: 8px 35px;
}

.imagePopup{
    &-meta{
        text-align: left;
        margin-bottom: 10px;
    }

    &-author{
        display: inline-block;
    }

    &-authorAvatarWrapper{
        display: inline-block;
    }

    &-authorAvatar{
        border-radius: 50%;
        @include size(48px)
    }

    &-authorName{
        display: inline-block;
    }

    &-date{
        display: inline-block;
    }
    &-sourceLogo{
        display: inline-block;
        height: 48px;
        float: right;

        & > img{
            height: 100%;
        }
    }
}

.imagePopup{
    &-imageRow{
        margin-bottom: 20px;
    }

    &-nav{
        position: absolute;
        top: 50%;
        width: 100%;
    }
    &-navPointer{
        font-size: 30px;
          position: relative;
    }
    &-prev{
        float: left;
        left: -25px;
    }
    &-next{
        float: right;
        right: -25px
    }
    &-imageWrapper{
        position: relative;
        margin-bottom: 7px;
        text-align: center;
    }
    &-image{
        max-width: 100%;
    }
    &-sourceLink{
        text-align: right;
    }
    &-title{
        text-align: left;
        font-size: 16px;
        line-height: 1.35;
    }
}

.imagePopup{
    &-socialShare{}
}