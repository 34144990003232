.cruiseSearch{
  
}

.cruiseSearch-how{
  margin-bottom: 20px;
  text-align: center;

  .tooltip-inner{
    padding: 20px;
    width: 550px;
    min-width: 550px;
    text-align: center;
    font-size: 18px;
  }
}
.cruiseSearch-how-header{
  font-size: 18px;
  color: $ca-blue;
  font-weight: 600;
}
.cruiseSearch-bookBtn{
  width: 180px;
  margin-top: 0px;
  font-size: 16px;
}

.cruiseSearch-blissSection{
  &-filter{
    margin: 0px 0px 20px;
  }
}


.cruiseSearch-other{
  > .panel-group > .panel{
    border: 0px;
    border-bottom: 1px solid #ddd;
    box-shadow: none;
    border-radius: 0px;
  }
  > .panel-group > .panel > .panel-heading{
    background-color: #ffffff;
  }  > .panel-group > .panel > .panel-heading > .panel-title{
  }

  a.accordion-toggle{
    &:hover{color: $ca-blue}
    &:focus{color: $ca-blue}

  }
}
.cruiseSearch-cruiseLine{
  &-legend { @include f(1.2em, $a: $c) }
  &-label { @include f(1.2em, $wn) }
  &-input { margin-bottom: 10px; }
}

.cruiseFilter-length-name { margin-bottom: 4px; padding-top: 6px; @include f(1.4em, $ws, $c) }

.cruiseSearch-loadMoreBtn { font-size: 1.4em; color: $ca-blue }
.cruiseSearch-loadMoreBtn:hover { color: $ca-blue}