.footer {
  margin-top: 40px;
  border-top: $default-border;

  &-container {
    margin: auto;
  }
  &-col {
    margin-top: 28px;
  }
  &-col-border {
    border-right: 1px solid #9c9c9c;
    border-left: 1px solid #9c9c9c
  }

  &-column-group {
    text-align: center;
  }
  &-column {
    display: inline-block;
    vertical-align: top;
    width: 174px;
    margin:0 10px;
    @media screen and (max-width: 1200px) {
      width:165px;
      margin:0 10px;
    }
  }

  &-cta {
    margin: 0 0 8px;
    @include f(1.4em, $ws, $c: #14323c);
  }
  &-cta + img {
    margin-bottom: 5px;
  }

  &-map {
    text-align: left;
  }
  &-map-links {
    @include f(15px, $wn, $lh: 34px, $c: #14323c);
    cursor: pointer;
    letter-spacing: -0.02em;
  }
  &-map-links:hover {
    text-decoration: none;
    color: $ca-blue;
  }

  &-socialLinks {
    text-align: right;
  }
  &-socialLinks-link {
    color: #14323c;
    font-size: 24px;
  }
  &-socialLinks-link:hover {
    color: $ca-blue;
  }

  &-copyright {
    text-align: center;
    background-color: #14323c;
  }
  &-copyright-link {
    color: white;
    margin-right: 40px;
  }
  &-copyright-link:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  &-copyright-text {
    line-height: 40px;
    margin-bottom: 0px;
    color: white;
  }

  .highlightBG {
    padding-top: 18px;
    margin: 0px;
  }
  .col-xs-15,
  .col-sm-15,
  .col-md-15,
  .col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-xs-15 {
    width: 20%;
    float: left;
  }
  @media (min-width: 768px) {
    .col-sm-15 {
      width: 20%;
      float: left;
    }
  }

  @media (min-width: 992px) {
    .col-md-15 {
      width: 20%;
      float: left;
    }
  }

  @media (min-width: 1200px) {
    .col-lg-15 {
      width: 20%;
      float: left;
    }
  }
  .footer-column-group {
    padding-top: 20px;
  }
}
