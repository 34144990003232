@mixin balloonPointer{
  &:before{
    display: block;
    position: absolute;
    right: 6px;
    top: -12px;
    content: "";
    background: url("../img/common/balloon+pointer.png") no-repeat center center;
    @include size(20px 12px);
  }
}

.balloon{
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  z-index: 1030;

  @include balloonPointer;

  &--inSlider{
    width: 292px;
    top: 75px;
    right: 20px;
  }

  &--inHomePage{
    width: 273px;
    top: -50px;
    right: -222px;
    &:before{
      right: 235px;
      top: 36px;
      background-image: url("../img/common/balloon+pointer_reverse.png");
    }
  }

  &-section{
    padding: 8px 10px;

    &--socialActions{
      @include box-shadow(0px 2px 2px 0px rgba(50, 50, 50, 0.15));

      > .socialActions{
        margin-bottom: 0px;
      }

    }
  }
}