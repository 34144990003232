.loginPage{
  padding-top: 20px;

  &-header{
    margin-bottom: 50px;
  }

  &-facebookLogin{
    text-align: center;
  }

  &-facebookBtn{
    display: inline-block;
  }

  &-googleBtn{
    display: inline-block;
    width: 353px;
  }

  &-terms{
    text-align: center;
    font-size: 16px;
    color: #8c8c8c;
  }
}

.loginPage-form{
  margin: 0px auto 20px;
  width: 280px;
  text-align: center;
  @media (max-width: $screen-xs-max) {
    width: 100%;
    padding: 10px;
  }

  &-header{
    text-transform: uppercase;
    color: #8c8c8c;
    font-size: 21px;
  }

  & > form .form-group{
    margin-bottom: 30px;
  }

  #required{
    position: absolute;
    left: -9999px;
  }

  &-additional{ text-align: left; margin-top: -20px }
  &-terms label{ font-weight: 500 }
  &-register{font-weight: normal; cursor: pointer}
  &-forgot{ float: right; margin-top: 20px; }

  &-submitBtn{
    margin: 0 auto;
    display:block;
    font-size: 18px;
    letter-spacing: -0.04em;
    text-transform: none;
    font-weight: bold;
  }

}