.love-bloggers,
.travel-lovers {
    font-family: Open Sans;
    width: 750px;
    margin: 0 auto 10px;

    .title {
        margin-top: 20px;
        text-align: center;
        font-size: 60px;
        color: #39bbed;
    }
}

.love-bloggers-photo,
.travel-lovers-photo {
    line-height: 2em;
    font-size: .8em;
    font-weight: bold;
}

.love-bloggers {
    h2 {
        margin: 0;
    }
}

.love-bloggers-intro {
    padding: 0 40px 60px; 
    border-bottom: 1px solid gray;
}

.love-bloggers-item {
    border-bottom: 1px solid gray;
    padding: 60px 0;
}

.love-bloggers-photo {
    float: left;
    padding: 0 40px 0 30px;
    width: 250px;

    img {
        width: 100%;
        display: block;
    }
}

.love-bloggers-summary {
    padding: 60px 0;
    text-align: center;
    font-size: 1.5em;
}


.travel-lovers {
    h2, h3 {
        margin: 0 0 20px;
        font-size: 25px;
    }
}

.travel-lovers-section {
    padding: 25px 0;
    border-top: 1px solid gray;
}

.travel-lovers-photo {
    margin-bottom: 25px;
    
    img {
        display: block;
        max-width: 100%;
    }
}

.travel-lovers-hotels {
    padding-top: 10px;
    text-align: center;

    h4 {
        margin: 15px 0;
        font-size: 15px;
        font-weight: bold;
    }
}

.travel-lovers-intro {
    padding-bottom: 10px;
    text-align: center;
}

.travel-lovers-columns {
    & > div {
        padding: 0 0 0 20px;
        float: left;
        width: 50%;
        border-left: 1px solid gray;

        &:first-child {
            padding: 0 20px 0 0;
            margin-right: -1px;
            border-right: 1px solid gray;
            border-left: 0;
        }
    }
}

.travel-lovers-payment {
    padding: 40px 0;
    text-align: center;
}

.check-score {
    position: relative;
    top: 30px;
    width: 600px;
    margin: auto;
    white-space: nowrap;
    text-align: center;
    line-height: 35px;
}

.check-score-lt {
    width: 380px;
    float: left;
}

.check-score-rt {
    width: 200px;
    float: right;
}

.check-score-example {
    font-size: 13px;
}

.check-score-result {
    font-size: 24px;
}