.image-edit-shortHandMenu {
  position: absolute;
  left: 19px;
  top: 2px;
  width: 70px;
}
.image-edit-shortHandMenu-list{
  & > li{
    margin-bottom: 5px;
    cursor: pointer;
  }
}