%authorName{
  font-size: 14px;
  font-weight: bold;
  color: #8c8c8c;
}

%questionHeader {
  font-size: 21px;
  font-weight: 600;
}

%userAvatar{
  margin-right: 10px;

}

.qa-list{
  &-item{
    border-bottom: 1px solid #8c8c8c;
    padding-bottom: 10px;
    min-height: 60px;
    margin-bottom: 25px;
  }

  &-link{
    background: url("/static/style/components/arrow-right.png") no-repeat 100% 7px;
    padding-right: 30px;
    display: block;
    text-indent: 0px;
  }


}

.qa-ask{
  @include ca-button;

  &-input{}
  &-addon{}
  &-button{
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
  }
  &-help{
    font-size: 18px;
    color: #8c8c8c;
    text-align: center;
  }

}

.qa-recent{
  margin-top: 60px;

  &-content{
    margin-left: 66px;
  }

  &-title{
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 300;
    color: #333;
  }

  &-userAvatar{
    float: left;
    margin-right: 10px;
  }
  &-thumbnail{
    margin-top: -5px;
    @include size(56px);
  }
  &-question{
    @extend %questionHeader;
    margin-bottom: 2px;
    color: #393b3b;
  }
  &-authorName{
    @extend %authorName;
  }
  &-date{
    font-size: 14px;
    color: #8c8c8c;
    font-weight: normal;
  }
}

.qa-founded{
  margin-top: 60px;

  &-header{
    font-size: 14px;
    text-indent: 0px;
  }
  &-header--related{
    margin-bottom: 20px;
  }

  &-question{
    @extend %questionHeader;
    color: #333333;
    margin-top: 0px;
    line-height: 40px;
  }
  &-question--asked{
    display: inline-block;
    vertical-align: text-bottom;
    text-indent: 0px;
  }
  &-authorName{
    @extend %authorName;
    margin-top: 5px;
  }

  &-userAvatar{
    @extend %userAvatar;
    display: inline-block;
    vertical-align: middle;
    margin-left: -105px;
    margin-right: 3px;
  }
  &-related{
    margin-top: 40px;
    clear: both;
    text-indent: 20px;
  }
}

.qa-confirmAsking{
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;

  &-header{
    color: $ca-blue;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &-btn{
    font-size: 21px;
    font-weight: 600;

  }
}

.qa-single{
  margin-top: 30px;
  margin-bottom: 30px;

  &-content{
    margin-left: 118px;
  }

  &-share{
    margin: 30px 0px;
  }

  &-question{
    @extend %questionHeader;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  &-authorName{
    @extend %authorName;
    margin-top: 10px;
  }
  &-userAvatar{
    @extend %userAvatar;
    margin-right: 20px;
    float: left;
  }
  &-meta{
    margin-top: 0;
  }
}