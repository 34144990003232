.cruiseFilter-price {
  background-color: #0cb2eb;
  text-align: left;
  padding: 30px 10px 20px;
  color: #ffffff;

  &-header {
    margin: 0px;
    padding: 10px 0px 10px 4px;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.02;
    color: #ffffff;
  }
  &-header > i{
    border: 2px solid white;
    padding: 4px 9px;
    border-radius: 50%;
    font-size: 22px;
  }
  &-legend{
    text-align: center;
    font-weight: 600;
  }
  &-done{
    float: right;
    color: #ffffff;
    &:hover{color: #ffffff;}
  }
}