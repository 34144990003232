$ligth: 300;
$normal: 400;
$semibold: 600;
$bold: 700;

$wl: $ligth;
$wn: $normal;
$ws: $semibold;
$wb: $bold;

$ln: 20em / 14;
$ln50: $ln / 2;
$ln33: $ln / 3;

$header-lh: 1.1em;
$p-lh: 1.42857em;


$cruiseable-blue: rgba(32, 184, 236, 1);
$ca-blue: $cruiseable-blue;
$ca-blue-darken: darken($ca-blue, 10%);
$ca-red: #ED7966;
$ca-yellow: #F7F4ED;
$ca-black: #2E2D2D;

$grey: #ccc;
$light-gray: #e5e5e5;
$ca-grey: $light-gray;
$ca-grey2: #9c9c9c;
$ca-grey3: #878787;
$ca-bg-grey: #f8f8f8;
$ca-txt-grey: #7f929c;
$ca-text-grey2: #454545;
$ca-color-placeholder: #7c7c7c;

$ca-text: $ca-black;
$fontColor: $ca-text;

$ca-sand: #F7F4ED;
$ca-sand-border: #D6C396;

$ca-sand-dark: #EDE4CE;
$ca-sand-dark-border: darken(#EDE4CE, 20%);

$radius: 6px;




$l: left;
$c: center;
$r: right;
$j: justify;

$pa: absolute;
$pr: relative;
$pf: fixed;
$ps: static;

$quicksand: 'Quicksand', sans-serif;
$openSans: 'Open Sans', sans-serif;

$border-grey: 1px solid $grey;
$default-border: 1px solid $light-gray;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$screen-height-xs: 480px;
$screen-height-sm: 640px;
$screen-height-md: 906px;
$screen-height-lg: 1024px;