.glossary { border-top:1px solid #ccc; width: 750px }
.glossary-header{ font-size:16px; font-weight:400; padding-top:6px; padding-bottom:0px;}
.glossary-content { font-size:16px; line-height: 24px; }
.glossary-list { width:140px; display: inline-block; font-size:16px !important; line-height:24px; vertical-align:top; font-weight:bold; padding-left:10px; padding-top:6px; padding-bottom:6px;}
.glossary-list-header { padding-bottom:10px; font-weight:700;}
.glossary-header div:nth-child(2) { width:360px; font-weight:normal; font-size:16px !important;}
.glossary-content div:nth-child(2) a { color:#BAE6F5; font-size:16px !important; font-weight:bold;}
.glossary-header div:nth-child(3) { width:210px;font-size:14px !important; }
.glossary-header div:nth-child(3) a { color:#BAE6F5; font-size:14px !important;text-decoration:none !important;}
.glossary-header div.glossary-content:nth-child(odd) {background: #F2EDE1; font-size:14px !important;}
.glossary-header div.glossary-content:nth-child(even) {background: #FFF}