.disqus{ 
  a {
    transition: color linear .1s;
    text-decoration: none;
  }
  menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  footer {
    margin: 4px 0 0;
  }
  footer a {
    color: rgba(0,39,59,.35);
    font-weight: 500;
  }
  footer menu li {
    float: left;
    list-style-type: none;
    margin: 0 7px 0 0;
    font-size: 13px;
  }
}

.disqus-title{
  font-size: 36px;
  font-weight: 600;
}

.disqus-post-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.disqus-post-children{
  margin-left: 60px;
}

.disqus-avatar {
  float: left;
  margin-right: 12px;
  margin-bottom: 12px;

}

.disqus-avatar--post{
  margin-right: 0;
  margin-bottom: 10px;
}

.disqus-avatar img {
  display: block;
  border-radius: 50%;
  width: 68px;
  height: 68px;
}

.disqus-avatar--post img {
  width: 100%;
  height: auto;
}

.disqus-post-content{
  position: relative;
  margin-bottom: 24px;
  transition: all .2s ease-in-out;
  @include clearfix;
}

.disqus-post-content:hover header .disqus-time-ago, 
.disqus-post-content:hover footer a {
    color: rgba(0,39,59,.5);
    text-decoration: none;
}

.disqus-post-body{
  overflow: hidden;
}

.disqus-author{
  font-weight: 700;
  font-size: 13px;
  color: rgb(32, 184, 236);
  &--post{
    text-align: center;
  }
}

.disqus-time-ago, a.disqus-time-ago{
  font-weight: 500;
  font-size: 12px;
  color: #a5b2b9;
  color: rgba(0,39,59,.35);
}

.disqus-post-message{
  font-size: 16px;
}

.disqus-bullet{
  padding: 0 4px;
  font-size: 75%;
  color: #ccc;
  line-height: 1.4;
  .post-content &{
    padding: 0;
  }
  .post-content footer &{
      line-height: 1.5;
  }
}

.post-content footer .voting {
  border-radius: 3px;
  position: relative;
}
.disqus-post-content footer{
  & .disqus-voting{
    transition: opacity .3s;
    border-radius: 3px;
    position: relative;
    font-size: 14px;

    & .disqus-vote-down,
    & .disqus-vote-up{
      position: relative;
      display: inline-block;
      padding: 0 3px;
      line-height: .85;
      height: 11px;
      min-height: 14px;  
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        right: -6px;
        bottom: -10px;
      }
      &:hover{
        color: rgba(0,39,59,.8);
      }
    }

    & .disqus-vote-up{
        color: #2e9fff;
      &.upvoted{}
      &:after{
        right: -3px;
        left: -5px;
      }
    }

    & .disqus-vote-down{
      padding-right: 0;
      padding-left: 6px;
      border-left: 2px solid;
      border-color: #eaedee;
      border-color: rgba(0,39,59,.08);
        color: #f55567;
      &.downvoted{}
    }
  }

}

.disqus-btn{
  margin-top: 10px;
  padding: 6px 24px;
}
.disqus-btn--post{
    float: right;
}

.disqus-no-posts{
  text-align: center;
  color: #3f4549;
  opacity: .6;
}

.disqus-media{
  list-style: none;
  margin-top: 20px;

  li{
    display: inline-block;
    margin: 5px;
  }

  img {
    max-height: 100px;
  }
}

.disqus-post-list--inCols {
  margin-left: 0px;
 // @include columns(2);
 .disqus-post{
    width: 50%;
    margin-right: 0%;
    padding-right: 20px;
    margin-bottom: 14px;
    &:nth-child(odd) {
        float: left;
        clear: left;
    }
    &:nth-child(even) {
        float: right;
        clear: right;
    }
 }
 .disqus-post-content{
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    margin-bottom: 14px
 }
}

.disqus-threadLink {
  clear: both;
  margin-top: 10px;
  margin-right: 20%;
  padding-top: 6px;
}