.community{

    &-header{
        text-align: center;
        font-size: 36px;
        font-weight: 500;
    }

    &-headerTwo{
      font-family: "Quicksand";
      letter-spacing: -0.8px;
    }

    &-subheader{
        text-align: center;
        font-size: 18px;
    }

    &-tip{
          text-align: center;
          font-size: 24px;
          margin-top: 12px;
          > a{
            text-decoration:  none;
          }
    }

    &-lead{
        text-align: center;
        font-size: 30px;
    }

    &-sharePhoto{
        text-align: center;
        margin-bottom:  30px;
    }

    &-sharedImages{
        margin-bottom: 20px;
    }

    &-tint{
        @include clearfix;
        background-color: #F7F4ED;
        border-radius: 6px;
        padding: 10px 20px 20px;
        &-toLeft{
            margin-left: -20px;
        }
        &-toRight{
            margin-right: -20px;
        }
    }
}

.community-go-to{
    margin-top: 10px;
    margin-bottom: -20px;
    float: right;
}

.community-subscribe{
    margin: 50px auto;
}

.community-disqus{
    .disqus-author{
        font-weight: 500;
    }

    .disqus-media{
        padding-left: 0px;

        li {
            margin-left: 0px;
            margin-right: 30px;
        }
        img {
            max-width: 100%;
            max-height: initial;
        }
    }

    .disqus-threadLink{
        font-weight: 700;
    }
}
.community-comments-header{
    margin-top: 40px;
    margin-bottom: 30px;
}
.community-comments{
    margin-top: 18px;
    position: relative;

    &-header{
        margin-top: 40px;
        margin-bottom: 30px;
    }

    &-subheader{
        margin-bottom: 18px;
        font-size: 24px;
    }

    .disqus-thread-wrap{
        position: relative;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          border-right: 1px solid #CCCCCC;
          left: 48%;
        }
    }

}

.community-generalDiscussion{
    &-header{
        font-family: "Quicksand"
    }
}
