.callInfo{
  text-align: center;

  &-title{
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: -0.02em;
    margin-bottom: 0px;

  }
  &-number{
    font-size: 36px;
    letter-spacing: -0.04em;
    margin-bottom: 0px;
  }
  &-info{
    @extend .callInfo-title;
  }
}