.userListing{
  text-align: left;
  border-bottom: 1px solid #E5E5EB;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.userListing-avatar{
  border-radius: 50%;
  width: 96px;
  height: 96px;
  border: 1px solid #616161;
  padding: 2px;
  background-color: white;
  float: left;
}
.userListing-data{
  margin-left: 105px;
}
.userListing-name{
  color: $ca-blue;
  font-size: 14px;
  font-weight: 600;
}
.userListing-brand,
.userListing-location{
  font-size: 12px;
  color: #616161;
}
.userListing-message{}
.userListing-messageLabel{
  font-size: 11px;
  color: $ca-blue;
}