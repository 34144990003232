$border-color: #cccccc;
$label-color: #cccccc;

$link-color: #8C8C8C;
$active-color: $ca-blue;
$inactive-color: #cccccc;

$content-headline-color: #cccccc;
$content-color: #cccccc;

$base-size: 30px;

$border: 1px solid $border-color;

.routeTable{
  border-bottom: $border;


  &-head{
    height: $base-size*1.5;
    border-top: $border;
    border-bottom: $border;
    padding-top: 21px; //Veritcal align bottom;
  }
  &-head-desc{
    color: $label-color;
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.04em;
  }
  &-head-desc--day{margin-left: 15px}
  &-head-desc--location{margin-left: 20px}

  &-body{
    border-left: $border;
    margin: $base-size/2 0px;
  }

  &-list{
    margin: $base-size/2 0px;
  }
  &-list-item{
    padding-left: 10px;
    margin-bottom: 10px;
    color: $link-color;
    font{
      size: 16px;
      weight: 400;
    }
    line-height: 24px;

    &--active{color: $active-color;}
    &--inactive{color: $inactive-color;}
  }
  &-list-day{
    padding-right: 32px;
    display: inline-block;
    text-align: right;
    width: 60px;
  }
  &-list-link{
    display: block;
    cursor: pointer;
    color: $link-color;
    @include transition (background 1.0s ease-out);
    &:hover{
      background-color: #fcfcfc;
      text-decoration: none;
      color: $link-color;
    }
    &--active{color: $active-color;}
    &--inactive{color: $inactive-color;}
  }


  &-location{
    padding: 0px $base-size/2;
    color: $content-color;
  }
  &-location-name{
    margin-top: 0px;
    font:{
      size: 24px;
      weight: 500;
    }
    letter-spacing: -0.02em;
  }
  &-location-description{
    font-size: 15px;
    line-height: 22px;
  }
}