.hearting{
  position: relative;
  text-align: left;
  display: inline-block;
  &-message{
    position: absolute;
    top: -42px;
    left: 50%;
  }
  &-message-text{
    position: relative; left: -75px; top:0px; z-index: 1025;
    border-radius: 50%; border: 1px #cccccc solid; padding: 22px 4px;
    width: 100px; height: 100px;
    background-color: rgba(255,255,255, 0.95);
    font-size: 14px; font-weight: 600; color: $ca-red; text-align: center;

    &.success{
      padding: 16px 4px;
    }
    span{
      display: block;
      font-size: 30px;
      line-height: 25px;
    }
  }
  &-loader{
    text-align: center;

    .hearting--large &{
      @include size(38px)
    }
  }

  &:hover i.fa-heart{
    color: $ca-red;
  }

  &--active, &--active:focus{
    color: $ca-red;
  }

}

.hearting--onLeft{
  .hearting-message-text{
    top: 40px;
    left: -80px;
  }
}
@include fade_zoom('.hearting-message-text');