.prices{
  width: 100px;
  color: white;
  text-align: center;

  &-description{
    background-color: #4f4f4f;
    padding: 4px 0px 10px;
  }
  &-name{
    font-size: 14px;
    font-weight: bold;
  }
  &-price{
    font-size:24px;
    line-height: 40px;
    letter-spacing: -0.06em;
    display: block;
  }

  &-checkbox{
    background-color: $ca-blue;
    @include border-bottom-radius(4px);
    margin: 0px;
  }
  &-checkbox input[type="checkbox"]{
    float: none;
    vertical-align: top;
  }
  &-checkbox-label{
    font:{size: 14px; weight: 600}
    letter-spacing: -0.04em;

    .checkbox &{font-weight: 600}
  }

}