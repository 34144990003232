$i: 9;
@while $i > 0 {
  .u-spacer-#{$i} { margin-top: 10px * $i; }
  $i: $i - 1 !global;
}
$i: 9;
@while $i > 0 {
  .u-spacer-b-#{$i} { margin-bottom: 10px * $i; }
  $i: $i - 1 !global;
}


.table-col-3{
   tr> td{
    width: percentage(1 / 3);
  }
}
.table-col-4{
   tr> td{
    width: percentage(1 / 4);
  }
}