.textList{
  $font-size: 12px;

  &-smallHeader{
    margin: 0 0 5px;
    font-size: $font-size;
    color: rgba(0,0,0,0.35);

    &--pageFontColor{
      color: $fontColor;
    }
  }
  &-content{
    font-size: $font-size;
    color: $cruiseable-blue
  }
  &-link{
    font-size: $font-size;
    color: $cruiseable-blue;
  }

  &--bigger {
    $font-size-bigger: 16px;
    margin-top: 26px;

    .textList-smallHeader {
      font-size: $font-size-bigger;
      display: inline-block;
      float: left;
    }
    .textList-content {
      margin-left: 40px;
      font-size: $font-size-bigger;
    }
    .textList-link {
      font-size: $font-size-bigger;
    }
  }
}

.commaSepareted{
  &:after{
    content: ", ";
  }
  &:last-child:after{
    content: "";
  }
}