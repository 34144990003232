.groupCruises {
    font-size: 20px;

    .planCruise-section-head h3 {
        white-space: nowrap;
    }
}

.groupCruises-faq {
    padding: 35px 50px 5px;
    
    > div {
    	margin-top:20px;
    	margin-bottom:20px;
    }
}

.groupCruises-options {
    ul {
        width: 811px;
        margin: 0 auto 55px;
        padding: 0;
        text-align: center;
    }

    li {
        margin: 0;
        float: left;
        display: block;
        padding: 20px 0;
        width: 405px;
    }

    li:first-child {
        width: 406px;
        border-right: 1px solid #000;
    }

    a {
        display: block;
        text-decoration: none;
    }

    .btn {
        margin: 0 80px 10px;
        width: auto;
        font-size: 30px;
    }

    p {
        margin: 0;
    }
}

.groupCruises-kinds {
	font-size: .8em;
}

.groupCruises-destinations {
    margin-top: 30px;
    text-align: center;

    img {
        display: block;
        margin: 0 auto 10px;
    }

    p, ul, li {
        margin: 0;
        padding: 0;
        display: inline;
        line-height: 30px;
    }

    li:first-child:before {
        display: none;
    }

    li:before {
        margin: 0 7px 0 2px;
        font-size: 30px;
        display: inline-block;
        content:"\2022";
        vertical-align: top;
    }
}

.groupCruises-step1 {
    width: 850px;
    margin: auto;

    .groupCruises-faq {
        padding: 0;

        dt {
            width: 255px;
        }

        dd {
            width: 595px;
        }
    }
}

.groupCruises-switch {
    font-size: 24px;
}

.groupCruises-form {
    width: 700px;
    margin: 0 auto 30px;
    padding: 20px;
    text-align: center;
    background: #EDE9DF;

    .form-control {
        width: 180px;
    }
}

.groupCruises-step {
    margin: 40px 0 25px;
    font-size: 26px;
    text-align: center;
}

.groupCruises input[required]::-webkit-input-placeholder:after {
  content: "*";
  color: #f72a4c; }

.groupCruises input[required]::-moz-placeholder:after {
  content: "*";
  color: #f72a4c; }

.groupCruises input[required]:-moz-placeholder:after {
  content: "*";
  color: #f72a4c; }

.groupCruises input[required]:-ms-input-placeholder:after {
  content: "*";
  color: #f72a4c; }

  .groupCruises-thankYou {
    text-align: center;
    font-size: 20px;

    .contentListing-subtitle {
        margin: 50px 0;
        font-size: 24px;
    }

    img {
        display: block;
        margin: 15px auto;
        border: 1px solid $ca-grey;
    }
  }

.contentListing-title.group-title{
    font-size: 36px;
    font-family:'Open Sans';
}
.contentListing-subtitle.group-title{
    font-size: 24px;
    font-family:'Open Sans';
}