.contentListing-title { margin-top: 30px; text-align: center; }
.contentListing-subtitle { @include f(1.4em, $wn, $a: $c, $lh: 1.3); }
.contentListing-caption {
  overflow: hidden;
  word-wrap: break-word;;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #333;
  letter-spacing: -0.04em;
  text-align: left;
}
.contentListing-caption > a { @include ca-link; @include f($c: $ca-black); }
.contentListing-sortingWrapper {
  text-align: right;
  margin-top: -37px;
  padding-bottom: 15px
}

.contentListing-createListWrapper {
  margin:-45px 0 11px
}

.contentListing-tagSearch{
  margin-bottom: 20px;
}

.listing-sorting{
  .listing-sorting-link{
    display: inline-block;
    color: #4E4E4E;

    &.active{
      color: #4E4E4E;
      font-weight: bold;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.contentListing .col-md-4:nth-child(3n+1){
  clear: left;
}