.listOfFeatures{
  $baseline: 22px;
  $fontSize: 14px;
  $headFontSize: 16px;

  font-size: $fontSize;
  text-align: center;

  &-label{
    color: #303030;
    font-size: $headFontSize;
    line-height: $baseline / $headFontSize;
    margin: 0px;
    font-weight: bold;
  }

  &-list{
    @extend .list-unstyled;
    margin-bottom: 0px;
  }

  &-feature{
    color: #5e5e5e;
    font-size: $fontSize;
    line-height: $baseline / $fontSize  }
}