.weatherWidget{
  text-align: center;
  margin-bottom: 20px;
  @include clearfix();

  &-container{}
  &-title{
    text-transform: uppercase;
  }
  &-imgWrapper{
    text-align: center;
  }
  &-img{
    max-width: 80%;
  }
  &-temp{
    color: $ca-blue;
    font-size: 40px;
    margin-top: 7px;
  }
}