$userAvatar-fontSizeBig: 19px;
$userAvatar-fontSize: 14px;
$userAvatar-fontSizeSmall: 12px;

$userAvatar-color-base: #000000;
$userAvatar-color-second: #a6a6a6;

$userAvatar-marginBetweenLines: 4px;

$userAvatar-color-base--inPost: #3d3c3c;

.userAvatar{
  text-align: center;
  color: $userAvatar-color-base;
  &-link{
    text-decoration: none !important;
  }
  &-thumbnail{
    @include size(80px 80px);
    border-radius: 50%;
  }
  &-name{
    font-size: $userAvatar-fontSizeBig;
    margin: 8px 0 $userAvatar-marginBetweenLines;
  }
  &-title{
    font-size: $userAvatar-fontSize;
    color: $userAvatar-color-second;
  }
  &-subline{
    color: #3d3c3c;
    font-size: $userAvatar-fontSizeSmall;
    margin-top: $userAvatar-marginBetweenLines;
    margin-bottom: $userAvatar-marginBetweenLines;
  }

  &-score{
    color: $cruiseable-blue;
    font-weight: bold;
  }

  &--inline{
    text-align: left;

    > .userAvatar-thumbnail,
    > .userAvatar-name{
      display: inline-block;
    }
  }

  &--smaller{

    > .userAvatar-thumbnail{
      @include size(40px 40px);
      border-radius: 20px;
    }

    > .userAvatar-name{
      font-size: $userAvatar-fontSize;
    }

  }
}
.userAvatar-name > a.userAvatar-link{
  &, &:visited, &:link {
    text-decoration: none !important;
    color: #4E4E4E;
  }
}


.userAvatar--inPost{
  color: $userAvatar-color-base--inPost;

  .userAvatar-name{
    @extend %openSans--bold;
    font-size: $userAvatar-fontSize;
    margin-bottom: $userAvatar-marginBetweenLines;
  }


}

