.postPage{
  &-headline.headline{
    margin-bottom: 30px;
  }
  &-headline.headline + h2 {
    margin-top: -26px;
  }

  &-blogInfo{
    text-transform: uppercase;
    padding: 20px 0px 0px;
    margin-bottom: -20px;
    font-size: 15px;
  }

  &-blogInfo-date{
    float: right;
  }

  &-fromInfo{
    font-size: 18px;
    text-align: left;
  }
}