.reasonList{
  &-item{}

  &-ico{
    @include size(45px);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    float: left;
    margin-left: -40px;
  }
  &-ico--price{
    background-image: url('/static/img/book-now/price-icon.png');
  }
  &-ico--accredited{
    background-image: url('/static/img/book-now/checkmark-icon.png');
  }
  &-ico--journalism{
    background-image: url('/static/img/book-now/newspaper-icon.png');
  }
  &-ico--community{
    background-image: url('/static/img/book-now/commmunity-icon.png');
  }
  &-ico--deals{
    background-image: url('/static/img/book-now/star-icon.png');
  }
  &-ico--service{
    background-image: url('/static/img/book-now/woman-icon.png');
  }

  &-content{
    margin-left: 20px;
  }
  &-header{
    font-size: 21px;
    letter-spacing: -0.06em;
    font-weight: 500;
  }
  &-body{
    font-size: 15px;
    line-height: 23px;
  }
}