
.discovery-ship-tool nav .listing-sorting-link.active {
    background-color: #0CB2EB;
    border-radius: 4px;
    padding: 0 6px;
    color: white;
}

.discovery-ship-tool .featuredImages-description-label {
    color: #4a4a4a;
}

.discovery-ship-tool nav a{
    font-size: 19px;
}
.discovery-ship-tool .dropdown-menu a {
  margin: 0 2em;
}

.discovery-ship-tool .featuredImages-description-label{
    font-size: 14px;
    line-height: 1.4;
}

.discovery-ship-tool {

  .listing-sorting-btn {
    background:none;
    color:#000;
    font-size:19px;
    color:#4E4E4E;
    text-transform: none;
    //&:hover + .dropdown-menu{
    //  display: block;
    //}
  }
  .listing-sorting {
    &:hover > .dropdown-menu {
      display: block;
    }

    &.open, &.active {
        background:#212121;
        border-radius:3px;
        .listing-sorting-btn {
          color:#fff;
          font-weight:600;
        }
    }

    &-btn {
      color:#000;
      padding:3px 35px;
    }
  }
  .listing-sorting-link {
    font-weight:600;
    font-size:14px;
    color:#4D4D4D;
    padding:3px 0;
    &.active {
      color: #FFFFFF;
      text-decoration: none;
      background-color: #000000;
      font-weight: 600;
      padding: 3px 10px;
      border-radius: 3px;
    }
  }
  .dropdown-menu {
    width:100px;
    border:none;
    box-shadow:none;
    // background:none;
    top:28px;
    text-align:left;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  }
}
